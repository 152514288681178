import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { OrderServiceService } from '../order-service.service';

@Component({
  selector: 'app-delivery-checkin',
  templateUrl: './delivery-checkin.component.html',
  styleUrls: ['./delivery-checkin.component.scss']
})
export class DeliveryCheckinComponent implements OnInit {

	currentOrg:string
	currentStamp:string
  qrDataCheckIn:any;
  qrDataCheckOut:any;

  constructor(private http: HttpClient, private afs:AngularFirestore, private os:OrderServiceService) {

  	this.os.currentOrgObs.subscribe( data => {
  		if(data){

  			this.currentOrg = data

  			this.afs.collection('key', ref=>ref.where('keyOrganization','==',this.currentOrg)).valueChanges().subscribe( data => {

				let body = {
					org: this.currentOrg
				}

			  	this.http.post<any>('https://us-central1-happen-project.cloudfunctions.net/getCheckIn', body).subscribe( data => {
			  		this.currentStamp = data.stamp

            this.qrDataCheckIn = {
              stamp:this.currentStamp,
              operation:'checkin'
            }

            this.qrDataCheckOut = {
              stamp:this.currentStamp,
              operation:'checkout'
            }

            this.qrDataCheckIn = JSON.stringify(this.qrDataCheckIn)
            this.qrDataCheckOut = JSON.stringify(this.qrDataCheckOut)

			  		console.log(this.currentStamp,data,data.stamp)
			  	});
  			})

  		}
  	})

  }

  ngOnInit(): void {
  }

}
