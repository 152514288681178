<div style="display: flex;">

	<div style="padding: 1rem;">
		
		<div style="display: flex;align-items: center;">
			<div style="margin-right: 1rem;">
				<h1>Member Information</h1>
			</div>
			<button mat-button (click)="saveQuestion()" >Save</button>
		</div>

		<div *ngIf="editProfile" style="display: flex;">
					
			<div style="width: 500px;">
				<div class="example-container">
				  <h2>Questions</h2>

				  <div
				    cdkDropList
				    #profileQuestionList="cdkDropList"
				    [cdkDropListData]="currentProfile"
				    [cdkDropListConnectedTo]="[questionTypeList]"
				    class="example-list"
				    (cdkDropListDropped)="drop($event)">
				    <div class="example-box" *ngFor="let item of currentProfile; let i = index;" cdkDrag>
				    	
				    	<div *ngIf="item.type == 'short'">
				    		<div style="display: flex;justify-content: space-between;width: 100%;">
				    			<div style="display: flex;flex-direction: column;">
				    				<p style="margin: 0;">Short text</p>
				    				<h2 style="margin: 0;">{{item.field}}</h2>
				    				<mat-checkbox [(ngModel)]="item.required">Required</mat-checkbox>
				    			</div>
				    			<div style="display: flex;align-content: center;">
				    				<button mat-button (click)="editField(i)">
				    					<mat-icon aria-hidden="false" >edit</mat-icon>
				    				</button>
				    				<button mat-button (click)="removeField(i)">
				    					<mat-icon aria-hidden="false" aria-label="Example home icon">delete</mat-icon>
				    				</button>
				    			</div>	
				    		</div>
				    	</div>

				    	<div *ngIf="item.type == 'long'">
				    		<div style="display: flex;justify-content: space-between;width: 100%;">
				    			<div style="display: flex;flex-direction: column;">
				    				<p style="margin: 0;">Long Text</p>
				    				<h2 style="margin: 0;">{{item.field}}</h2>
				    				<mat-checkbox [(ngModel)]="item.required">Required</mat-checkbox>
				    			</div>
				    			<div style="display: flex;align-content: center;">
				    				<button mat-button (click)="editField(i)">
				    					<mat-icon aria-hidden="false" >edit</mat-icon>
				    				</button>
				    				<button mat-button (click)="removeField(i)">
				    					<mat-icon aria-hidden="false" aria-label="Example home icon">delete</mat-icon>
				    				</button>
				    			</div>	
				    		</div>
				    	</div>

				    	<div *ngIf="item.type == 'date'">
				    		<div style="display: flex;justify-content: space-between;width: 100%;">
				    			<div style="display: flex;flex-direction: column;">
				    				<p style="margin: 0;">Date</p>
				    				<h2 style="margin: 0;">{{item.field}}</h2>
				    				<mat-checkbox [(ngModel)]="item.required">Required</mat-checkbox>
				    			</div>
				    			<div style="display: flex;align-content: center;">
				    				<button mat-button (click)="editField(i)">
				    					<mat-icon aria-hidden="false" >edit</mat-icon>
				    				</button>
				    				<button mat-button (click)="removeField(i)">
				    					<mat-icon aria-hidden="false" aria-label="Example home icon">delete</mat-icon>
				    				</button>
				    			</div>	
				    		</div>
				    	</div>

				    	<div *ngIf="item.type == 'time'">
				    		<div style="display: flex;justify-content: space-between;width: 100%;">
				    			<div style="display: flex;flex-direction: column;">
				    				<p style="margin: 0;">Time</p>
				    				<h2 style="margin: 0;">{{item.field}}</h2>
				    				<mat-checkbox [(ngModel)]="item.required">Required</mat-checkbox>
				    			</div>
				    			<div style="display: flex;align-content: center;">
				    				<button mat-button (click)="editField(i)">
				    					<mat-icon aria-hidden="false" >edit</mat-icon>
				    				</button>
				    				<button mat-button (click)="removeField(i)">
				    					<mat-icon aria-hidden="false" aria-label="Example home icon">delete</mat-icon>
				    				</button>
				    			</div>	
				    		</div>
				    	</div>

				    	<div *ngIf="item.type == 'auth-phone'">
				    		<div style="display: flex;justify-content: space-between;width: 100%;">
				    			<div style="display: flex;flex-direction: column;">
				    				<p style="margin: 0;">(Hidden) Authenticated Phone</p>
				    				<h2 style="margin: 0;">{{item.field}}</h2>
				    				<p>Required</p>
				    			</div>
				    		</div>
				    	</div>

				    	<div *ngIf="item.type == 'dropdown'">	
				    		<div style="display: flex;justify-content: space-between;width: 100%;">
				    			<div style="display: flex;flex-direction: column;">
				    				<p style="margin: 0;">Dropdown</p>
				    				<h2 style="margin: 0;">{{item.field}}</h2>
				    				<mat-checkbox [(ngModel)]="item.required">Required</mat-checkbox>
				    			</div>
				    			<div style="display: flex;align-content: center;">
				    				<button mat-button (click)="editField(i)">
				    					<mat-icon aria-hidden="false" >edit</mat-icon>
				    				</button>
				    				<button mat-button (click)="removeField(i)">
				    					<mat-icon aria-hidden="false" aria-label="Example home icon">delete</mat-icon>
				    				</button>
				    			</div>	
				    		</div>
				    		<div>
				    			<div style="display: flex;justify-content: space-between;width: 100%;align-items: center;">
				    				<h3 style="margin: 0;">Options</h3>
				    				<button mat-button (click)="addOption(i)">
				    					<mat-icon aria-hidden="false" aria-label="Example home icon">add</mat-icon>
				    				</button>
				    			</div>
					    		<ul>
					    			<li *ngFor="let option of item.option; let j = index;">
					    				<div style="display: flex;width: 100%;justify-content: space-between;align-items: center;">
					    					<p>{{option}}</p>
						    				<button mat-button (click)="removeOption(i,j)">
						    					<mat-icon aria-hidden="false" aria-label="Example home icon">clear</mat-icon>
						    				</button>
					    				</div>
					    			</li>
					    		</ul>
				    		</div>
				    	</div>


				    </div>
				  </div>
				</div>
				

			</div>

			<div style="width: 500px;">



				<div class="example-container">
				  <h2 style="margin: 0;">Available Question</h2>
				  <p>Drag and Drop into the profile</p>

				  <div
				    cdkDropList
				    #questionTypeList="cdkDropList"
				    [cdkDropListData]="questionType"
				    [cdkDropListConnectedTo]="[profileQuestionList]"
				    class="example-list"
				    (cdkDropListDropped)="drop($event)">
				    <div class="example-box" *ngFor="let item of questionType" cdkDrag>
				    	
				    	<div *ngIf="item.type == 'short'">
				    		<h3 style="margin: 0;padding: 0;">Short text</h3>
				    	</div>

				    	<div *ngIf="item.type == 'long'">
				    		<h3 style="margin: 0;padding: 0;">Long Text</h3>
				    	</div>

				    	<div *ngIf="item.type == 'date'">
				    		<h3 style="margin: 0;padding: 0;">Date</h3>
				    	</div>

				    	<div *ngIf="item.type == 'time'">
				    		<h3 style="margin: 0;padding: 0;">Time</h3>
				    	</div>

				    	<div *ngIf="item.type == 'auth-phone'">
				    		<h3 style="margin: 0;padding: 0;">(Hidden) Authenticated Phone</h3>
				    	</div>

				    	<div *ngIf="item.type == 'dropdown'">
				    		<h3 style="margin: 0;padding: 0;">Dropdown</h3>
				    	</div>


				    </div>
				  </div>
				</div>
				

			</div>


		</div>
		<div *ngIf="!editProfile">
			<h3>Questions:</h3>
			<p *ngFor="let question of currentProfile">{{question.field}}</p>
		</div>

	</div>




</div>


