import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterDate'
})
export class FilterDatePipe implements PipeTransform {

  transform(value: any[], date: any): unknown {
    
  	let matchDateArr = []

  	for(let order of value){

  		console.log(order.trayTimestamp.setHours(0,0,0,0),date)

  		let testDate = order.trayTimestamp.setHours(0,0,0,0)
  		let targetDate = date.setHours(0,0,0,0);


  		if(testDate == targetDate){
  			matchDateArr.push(order)
  		}
  	}

  	

    return matchDateArr;

  }

}
