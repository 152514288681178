import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderDialogComponent } from '../order-dialog/order-dialog.component';
import { OrderServiceService } from '../order-service.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { PermissionServiceService } from '../permission-service.service';

@Component({
  selector: 'app-member-config',
  templateUrl: './member-config.component.html',
  styleUrls: ['./member-config.component.scss']
})
export class MemberConfigComponent implements OnInit {

	currentOrg:string

	////permission
	createProfile:boolean
	editProfile:boolean

	memberProfileCollection:AngularFirestoreDocument<any>
	memberProfile:Observable<any>
	currentProfile:any


	  questionType = [
		{
			type:'short',
			field:'Enter your question',
			required:true
		},
		{
			type:'long',
			field:'Enter your question',
			required:true
		},
		{
			type:'date',
			field:'Enter your question',
			required:true
		},
		{
			type:'time',
			field:'Enter your question',
			required:true
		},
		{
			type:'auth-phone',
			field:'(Hidden) Authenticated Phone',
			required:true
		},
		{
			type:'dropdown',
			field:'Enter your question',
			option:['option1','option2','option3'],
			required:true
		},
	  ];

  constructor(private afs:AngularFirestore, public dialog:MatDialog, public os:OrderServiceService, public ps:PermissionServiceService) {

  	this.currentProfile = []

  	this.os.currentOrgObs.subscribe( data => {
  		console.log(data)
  		if(data){

  			this.currentOrg = data

  			this.memberProfileCollection = this.afs.doc('organization/' + data)
  			this.memberProfile = this.memberProfileCollection.valueChanges()
  			this.memberProfile.subscribe((data:any) => {
  				if(data.organizationMemberQuestion){
  					this.currentProfile = data.organizationMemberQuestion
  				}
  			})

  		}
  	})

	this.createProfile = true;
	this.editProfile = true;

	


   }

  ngOnInit(): void {
  }

  drop(event: CdkDragDrop<string[]>) {

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }

    this.questionType = [
		{
			type:'short',
			field:'Enter your question',
			required:true
		},
		{
			type:'long',
			field:'Enter your question',
			required:true
		},
		{
			type:'date',
			field:'Enter your question',
			required:true
		},
		{
			type:'time',
			field:'Enter your question',
			required:true
		},
		{
			type:'auth-phone',
			field:'(Hidden) Authenticated Phone',
			required:true
		},
		{
			type:'dropdown',
			field:'Enter your question',
			option:['option1','option2','option3'],
			required:true
		},
	  ];

  }

  editField(index){
  	let newField = prompt('Enter the title of this question:');
  	if(newField){
  		this.currentProfile[index].field = newField;
  	}
  }

  removeField(index){
  	this.currentProfile.splice(index,1);
  }

  addOption(index){

  	let newOption = prompt('Enter new option');

  	if(newOption){
	  	let optionArr = this.currentProfile[index].option;
	  	optionArr.push(newOption)
  	}

  }

  removeOption(i,j){
  	let optionArr = this.currentProfile[i].option;
  	optionArr.splice(j,1)
		this.currentProfile[i].option = optionArr
  }

  saveQuestion(){

  	this.afs.doc('organization/' + this.currentOrg).update({
  		organizationMemberQuestion:this.currentProfile
  	}).then( () =>{
  		alert('Done!')
  	})


  }

}