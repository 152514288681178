import { Component, OnInit, Inject } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore'; 
import { AngularFireStorage } from '@angular/fire/storage';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MenuServiceService } from '../menu-service.service';
import { Observable } from 'rxjs';
import { Ng2ImgMaxService } from 'ng2-img-max';

@Component({
  selector: 'app-item-dialog',
  templateUrl: './item-dialog.component.html',
  styleUrls: ['./item-dialog.component.scss']
})

export class ItemDialogComponent implements OnInit {

	name : string = "";
	price : number = 0;
	description : string = "";
	categoryNameName : string = "";
	tag : string = "";
	uploadImage : any;
	update : boolean = false;
	create : boolean = false;
	detail : boolean = false;
	itemsDetailCollection : AngularFirestoreCollection<any>;
	itemDetailList : Observable<Detail[]>;
	itemDetailArr : any[];
	delete : boolean = false;
	imageURL : string = "";
	categoryNameArr : any = [];
	categorySelectListName : string;
	itemStatus : boolean = false;
	imgWidth : number = 0;
	imgHeight : number = 0;

  	constructor(@Inject(MAT_DIALOG_DATA) public data : any, public ms : MenuServiceService, private storage : AngularFireStorage, private afs :AngularFirestore, public dialog : MatDialog, public resize:Ng2ImgMaxService) { 
  		
  		
  	}

  	ngOnInit() { 
  		if(this.data != null) {
			this.detail = true;
  			this.afs.collection<Category>('outlet/'+this.ms.currentOutlet+'/category/').doc(this.ms.currentCategory).valueChanges().subscribe( data => {
      			this.categoryNameArr = data;
      			this.categoryNameName = this.categoryNameArr.categoryName;
			})

  			for(var i = 0; i < this.ms.itemFilterArr.length; i++) {
  				if(this.ms.itemFilterArr[i].itemId == this.data.itemId) {
  					this.name = this.ms.itemFilterArr[i].itemName;
  					this.price = this.ms.itemFilterArr[i].itemPrice;
  					this.description = this.ms.itemFilterArr[i].itemDesc;  					
  					this.tag = this.ms.itemFilterArr[i].itemTag;
  					this.imageURL = this.ms.itemFilterArr[i].itemURL;
  					this.itemStatus = this.ms.itemFilterArr[i].itemStatus;
  				}
  			}



  		}
  		else {
  			this.create = true;
  		}
  	}

	createItem() {

		let newDocId = this.afs.createId();
		let status = true;
		const filepath = 'outlet/' + this.ms.currentOutlet + '/item/' + newDocId;
    	const task = this.storage.upload(filepath, this.uploadImage ).then( () => {
    		const ref = this.storage.ref(filepath);
        	let url = ref.getDownloadURL();
        	
        	url.subscribe( link => {

        		this.afs.doc('outlet/'+ this.ms.currentOutlet +'/item/' + newDocId).set({
		          itemName:this.name,
		          itemPrice:this.price,
		          itemDesc:this.description,
		          itemCategory:this.ms.currentCategory,
		          itemTag:this.tag,
		          itemURL:link,
		          itemStatus:status,
		        }).then( () => {})


        	});

    	});
		this.dialog.closeAll();
	}

	updateItem(id : string) {
		this.detail = false;
		console.log(id);
		this.update = true;

		if(this.uploadImage != null) {
			const filepath = 'outlet/' + this.ms.currentOutlet + '/item/' + id;
			const task = this.storage.upload(filepath, this.uploadImage ).then( () => {
	    		const ref = this.storage.ref(filepath);
	        	let url = ref.getDownloadURL();
	        	
	        	url.subscribe( link => {

	        		this.afs.doc('outlet/'+ this.ms.currentOutlet +'/item/' + id).update({
			          itemName:this.name,
			          itemPrice:this.price,
			          itemDesc:this.description,
			          itemCategory:this.categorySelectListName,
			          itemTag:this.tag,
			          itemURL:link,
			          itemStatus:this.itemStatus,
			        }).then( () => {})
	        	});
	    	});

		}
		else {
			this.afs.doc('outlet/' + this.ms.currentOutlet + '/item/' + id).update({
				itemName : this.name,
				itemPrice : this.price,
				itemDesc : this.description,
				itemCategory : this.categorySelectListName,
				itemTag : this.tag,
				itemStatus:this.itemStatus,
			}).then( () => {})
		}
		this.dialog.closeAll();

	}

	deleteItem(id : string, url : string) {
		this.afs.doc('outlet/' + this.ms.currentOutlet + '/item/' + id).delete();
		this.storage.storage.refFromURL(url).delete();
		this.dialog.closeAll();
	}

	uploadFile(event) {
		//this.uploadImage = event.target.files[0];
		let image = event.target.files[0];
		// console.log('image', image);
		let file = event.target.files[0];
		let img = new Image();
		let ratio = image.size / 50000;
		// let ratio = image.size / 45000;
		let width = 0;
		let height = 0;
		img.onload = function() {
			// console.log('w', img.width)
			// console.log('h', img.height)
			// console.log(img.height*0.3);

			width = img.width/Math.floor(ratio);
			height = img.height/Math.floor(ratio);
		}
		img.src = URL.createObjectURL(file);

		let interval = setInterval(() => {

			this.imgWidth = width;
			this.imgHeight = height;

			if(this.imgWidth != 0 && this.imgHeight != 0) {

				this.resize.resizeImage(image, this.imgWidth, this.imgHeight).subscribe( result => {
					this.uploadImage = result;
					// console.log(result);
					clearInterval(interval)
				}, error => {
					console.log('Image Resize Error', error);
				})
			}
	        
	      }, 100);

  	}

  	openUpdateItemDialog(id : string) {
  		this.detail = false;
		this.update = true;
		this.categorySelectListName = this.ms.currentCategory;
		// console.log('selcct', this.categorySelectListName);
  	}

  	openDeleteItemDialog(id : string) {
  		this.detail = false;
		this.delete = true;
  	}

}

export interface DialogData{
	itemName : string;
}

export interface Detail {
  itemName : string;
  itemPrice : number;
  itemDesc : string;
  itemCategory : string;
  itemTag : string;
  itemURL : string;
}

export interface Category {
  categoryName : string;
  categoryStatus : boolean;
  categoryIndex : number;
  categoryTray : string[];
}