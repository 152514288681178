import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'uncleanseField'
})
export class UncleanseFieldPipe implements PipeTransform {

  transform(value: string,cleanseStr:string[]): string {
    
  	
  	let uncleanse = value;
  	for(let phrase of cleanseStr){
  		uncleanse = uncleanse.replace(phrase,'');
  	}

    return uncleanse;




  }

}
