<div style="display: flex;">
	
	<div style="width: 300px;">

		<div style="display: flex;justify-content: space-between;padding: 15px;align-items: center;">
			<h3 style="margin: 0;">Profiles</h3>
			<button mat-raised-button (click)="addNewProfile()" *ngIf="createProfile">New Profile</button>
		</div>

		<mat-list role="list">
			<mat-list-item role="listitem" *ngFor="let profile of os.orderProfile | async" (click)="os.viewProfile(profile)" >{{profile.profileName}}</mat-list-item>
		</mat-list>



		
	</div>

	<div style="padding: 1rem;">
		
		<div style="display: flex;align-items: center;">
			<div style="margin-right: 1rem;">
				<p style="margin: 0;">current profile</p>
				<h1>{{os.currentProfile.profileName}}</h1>
			</div>
			<button mat-button (click)="updateProfileName()" >
				<mat-icon aria-hidden="false" aria-label="Example home icon">edit</mat-icon>
			</button>
		</div>

		<div *ngIf="editProfile" style="display: flex;align-items: center;">
			<mat-slide-toggle [(ngModel)]="os.currentProfile.profileStatus" (change)="os.toggleProfileActive()">Status</mat-slide-toggle>
			<mat-slide-toggle [(ngModel)]="os.currentProfile.profileAuth" (change)="os.toggleProfileAuth()">Authentication</mat-slide-toggle>
			

			
			<button mat-button (click)="os.updateProfileQuestion()">Save Changes</button>	
		</div>

		<div *ngIf="!editProfile">
			<p>Status: {{os.currentProfile.profileStatus}}</p>
			<p>Authentication: {{os.currentProfile.profileAuth}}</p>

		</div>

		<div *ngIf="editProfile" style="display: flex;">

			<div style="width: 500px;">
				<div class="example-container">
					<h2>Questions</h2>

					<div
					cdkDropList
					#profileQuestionList="cdkDropList"
					[cdkDropListData]="os.currentProfile.profileQuestion"
					[cdkDropListConnectedTo]="[questionTypeList]"
					class="example-list"
					(cdkDropListDropped)="drop($event)">
					<div class="example-box" *ngFor="let item of os.currentProfile.profileQuestion; let i = index;" cdkDrag>

						<div *ngIf="item.type == 'short'">
							<div style="display: flex;justify-content: space-between;width: 100%;">
								<div style="display: flex;flex-direction: column;">
									<p style="margin: 0;">Short text</p>
									<h2 style="margin: 0;">{{item.field}}</h2>
								</div>
								<div style="display: flex;align-content: center;">
									<button mat-button (click)="editField(i)">
										<mat-icon aria-hidden="false" >edit</mat-icon>
									</button>
									<button mat-button (click)="removeField(i)">
										<mat-icon aria-hidden="false" aria-label="Example home icon">delete</mat-icon>
									</button>
								</div>	
							</div>
						</div>

						<div *ngIf="item.type == 'long'">
							<div style="display: flex;justify-content: space-between;width: 100%;">
								<div style="display: flex;flex-direction: column;">
									<p style="margin: 0;">Long Text</p>
									<h2 style="margin: 0;">{{item.field}}</h2>
								</div>
								<div style="display: flex;align-content: center;">
									<button mat-button (click)="editField(i)">
										<mat-icon aria-hidden="false" >edit</mat-icon>
									</button>
									<button mat-button (click)="removeField(i)">
										<mat-icon aria-hidden="false" aria-label="Example home icon">delete</mat-icon>
									</button>
								</div>	
							</div>
						</div>

						<div *ngIf="item.type == 'date'">
							<div style="display: flex;flex-direction: column;">
								<div style="display: flex;justify-content: space-between;width: 100%;">
									<div style="display: flex;flex-direction: column;">
										<p style="margin: 0;">Date</p>
										<h2 style="margin: 0;">{{item.field}}</h2>
									</div>
									<div style="display: flex;align-content: center;">
										<button mat-button (click)="editField(i)">
											<mat-icon aria-hidden="false" >edit</mat-icon>
										</button>
										<button mat-button (click)="removeField(i)">
											<mat-icon aria-hidden="false" aria-label="Example home icon">delete</mat-icon>
										</button>
									</div>	
								</div>
								<div>
									<div style="display: flex;width: 100%;justify-content: space-between;align-items: center;">
										<p style="padding: 0;margin: 0;">Maximum Days in Future: {{item.maxDateFuture}}</p>
										<button mat-button (click)="editDate(i)">
											<mat-icon aria-hidden="false" aria-label="Example home icon">edit</mat-icon>
										</button>
									</div>					    			
								</div>
							</div>
						</div>

						<div *ngIf="item.type == 'time'">
							<div style="display: flex;flex-direction: column;">
								<div style="display: flex;justify-content: space-between;width: 100%;">
									<div style="display: flex;flex-direction: column;">
										<p style="margin: 0;">Time</p>
										<h2 style="margin: 0;">{{item.field}}</h2>
									</div>
									<div style="display: flex;align-content: center;">
										<button mat-button (click)="editField(i)">
											<mat-icon aria-hidden="false" >edit</mat-icon>
										</button>
										<button mat-button (click)="removeField(i)">
											<mat-icon aria-hidden="false" aria-label="Example home icon">delete</mat-icon>
										</button>
									</div>	
								</div>
								<div>
									<div style="display: flex;width: 100%;justify-content: space-between;align-items: center;">
										<p style="padding: 0;margin: 0;">Buffer Time: {{item.bufferMin}} min</p>
										<button mat-button (click)="editTime(i,'buffer')">
											<mat-icon aria-hidden="false" aria-label="Example home icon">edit</mat-icon>
										</button>
									</div>
									<div style="display: flex;width: 100%;justify-content: space-between;align-items: center;">
										<p style="padding: 0;margin: 0;">Minimum Time: {{item.minTime}}</p>
										<button mat-button (click)="editTime(i,'min')">
											<mat-icon aria-hidden="false" aria-label="Example home icon">edit</mat-icon>
										</button>
									</div>
									<div style="display: flex;width: 100%;justify-content: space-between;align-items: center;">
										<p style="padding: 0;margin: 0;">Maximum Time: {{item.maxTime}}</p>
										<button mat-button (click)="editTime(i,'max')">
											<mat-icon aria-hidden="false" aria-label="Example home icon">edit</mat-icon>
										</button>
									</div>
									<div style="display: flex;width: 100%;justify-content: space-between;align-items: center;">
										<mat-checkbox [(ngModel)]="item.timeOnly" style="margin:0 15px;">Time Only</mat-checkbox>
									</div>

								</div>
							</div>
						</div>

						<div *ngIf="item.type == 'promotion'">
							<div style="display: flex;justify-content: space-between;width: 100%;">
								<div style="display: flex;flex-direction: column;">
									<p style="margin: 0;">Promotion</p>
									<h2 style="margin: 0;">{{item.field}}</h2>
								</div>
								<div style="display: flex;align-content: center;">
									<button mat-button (click)="editField(i)">
										<mat-icon aria-hidden="false" >edit</mat-icon>
									</button>
									<button mat-button (click)="removeField(i)">
										<mat-icon aria-hidden="false" aria-label="Example home icon">delete</mat-icon>
									</button>
								</div>	
							</div>
						</div>

						<div *ngIf="item.type == 'dropdown'">	
							<div style="display: flex;justify-content: space-between;width: 100%;">
								<div style="display: flex;flex-direction: column;">
									<p style="margin: 0;">Dropdown</p>
									<h2 style="margin: 0;">{{item.field}}</h2>
								</div>
								<div style="display: flex;align-content: center;">
									<button mat-button (click)="editField(i)">
										<mat-icon aria-hidden="false" >edit</mat-icon>
									</button>
									<button mat-button (click)="removeField(i)">
										<mat-icon aria-hidden="false" aria-label="Example home icon">delete</mat-icon>
									</button>
								</div>	
							</div>
							<div>
								<div style="display: flex;justify-content: space-between;width: 100%;align-items: center;">
									<h3 style="margin: 0;">Options</h3>
									<button mat-button (click)="addOption(i)">
										<mat-icon aria-hidden="false" aria-label="Example home icon">add</mat-icon>
									</button>
								</div>
								<ul>
									<li *ngFor="let option of item.option; let j = index;">
										<div style="display: flex;width: 100%;justify-content: space-between;align-items: center;">
											<p>{{option}}</p>
											<button mat-button (click)="removeOption(i,j)">
												<mat-icon aria-hidden="false" aria-label="Example home icon">clear</mat-icon>
											</button>
										</div>
									</li>
								</ul>
							</div>
						</div>
						<div *ngIf="item.type == 'auth-user'">
							<div style="display: flex;justify-content: space-between;width: 100%;">
								<div style="display: flex;flex-direction: column;">
									<p style="margin: 0;">(Hidden) Authenticated User</p>
									<h2 style="margin: 0;">{{item.field}}</h2>
								</div>
							</div>
						</div>

						<div *ngIf="item.type == 'location'">
							<div style="display: flex;justify-content: space-between;width: 100%;">
								<div style="display: flex;flex-direction: column;">
									<p style="margin: 0;">Location</p>
									<h2 style="margin: 0;">{{item.field}}</h2>
								</div>
								<div style="display: flex;align-content: center;">
									<button mat-button (click)="editField(i)">
										<mat-icon aria-hidden="false" >edit</mat-icon>
									</button>
									<button mat-button (click)="removeField(i)">
										<mat-icon aria-hidden="false" aria-label="Example home icon">delete</mat-icon>
									</button>
								</div>	
							</div>
						</div>

						<div *ngIf="item.type == 'rider'">
							<div style="display: flex;justify-content: space-between;width: 100%;">
								<div style="display: flex;flex-direction: column;">
									<p style="margin: 0;">(Hidden) Rider</p>
									<h2 style="margin: 0;">{{item.field}}</h2>
								</div>
							</div>
						</div>

						<div *ngIf="item.type == 'whatsapp-send'">
							<div style="display: flex;justify-content: space-between;flex-direction: column;width: 100%;">
								<div style="display: flex;justify-content: space-between;width: 100%;">
									<div style="display: flex;flex-direction: column;">
										<p style="margin: 0;">(Hidden) WhatsApp Send</p>
										<h2 style="margin: 0;">{{item.field}}</h2>
									</div>
									<div style="display: flex;align-content: center;">
										<button mat-button (click)="editField(i)">
											<mat-icon aria-hidden="false" >edit</mat-icon>
										</button>
										<button mat-button (click)="removeField(i)">
											<mat-icon aria-hidden="false" aria-label="Example home icon">delete</mat-icon>
										</button>
									</div>	
								</div>

								<div style="display: flex;justify-content: center;flex-direction: column;">
									<mat-form-field>
										<mat-label>Receipient Field</mat-label>
										<mat-select [(ngModel)]="item.receipientField">
											<mat-option>None</mat-option>
											<mat-option *ngFor="let question of os.currentProfile.profileQuestion" [value]="question.field">{{question.field}}</mat-option>
										</mat-select>
									</mat-form-field>

									<mat-form-field class="example-full-width">
										<mat-label>Enter your message</mat-label>
										<textarea (input)="detectSummoner(item)" matInput [(ngModel)]="item.message" placeholder="To insert variables, type << to begin."></textarea>
									</mat-form-field>



								</div>

<!-- 				    			<div style="display: flex;align-content: center;">
				    				<button mat-button (click)="editField(i)">
				    					<mat-icon aria-hidden="false" >edit</mat-icon>
				    				</button>
				    				<button mat-button (click)="removeField(i)">
				    					<mat-icon aria-hidden="false" aria-label="Example home icon">delete</mat-icon>
				    				</button>
				    			</div> -->	
				    		</div>
				    	</div>


				    </div>
				</div>
			</div>

			<div class="example-container">
				<!-- Payment Method CheckBox -->

				<mat-accordion>
					<mat-expansion-panel>
						<mat-expansion-panel-header>
							<mat-panel-title>
								Cash On Delivery
							</mat-panel-title>
							<mat-panel-description>
								<mat-checkbox [checked]="os.codCheck" (change)="setPaymentMethod($event.checked, 'cod')"></mat-checkbox>
							</mat-panel-description>
						</mat-expansion-panel-header>

						<mat-form-field>
								<mat-label>Index</mat-label>
								<input matInput [(ngModel)]="os.codIndex">
						</mat-form-field>

					</mat-expansion-panel>

					<mat-expansion-panel>
						<mat-expansion-panel-header>
							<mat-panel-title>
								Bank Transfer
							</mat-panel-title>
							<mat-panel-description>
								<mat-checkbox [checked]="os.offlineCheck" (change)="setPaymentMethod($event.checked, 'offline')"></mat-checkbox>
							</mat-panel-description>
						</mat-expansion-panel-header>

						<mat-form-field>
								<mat-label>Index</mat-label>
								<input matInput [(ngModel)]="os.offlineIndex">
						</mat-form-field>

						<div>
							<mat-form-field>
								<mat-label>Bank</mat-label>
								<input matInput [(ngModel)]="os.bank">
							</mat-form-field>

							<mat-form-field>
								<mat-label>Account Number</mat-label>
								<input matInput [(ngModel)]="os.accountNumber">
							</mat-form-field>

							<mat-form-field>
								<mat-label>Account Name</mat-label>
								<input matInput [(ngModel)]="os.accountName">
							</mat-form-field>

						</div>



					</mat-expansion-panel>

					<mat-expansion-panel>
						<mat-expansion-panel-header>
							<mat-panel-title>
								Credit/Debit Card
							</mat-panel-title>
							<mat-panel-description>
								<mat-checkbox [checked]="os.stripeCheck" (change)="setPaymentMethod($event.checked, 'stripe')"></mat-checkbox>
							</mat-panel-description>
						</mat-expansion-panel-header>

						<mat-form-field>
								<mat-label>Index</mat-label>
								<input matInput [(ngModel)]="os.stripeIndex">
						</mat-form-field>

						<mat-slide-toggle [(ngModel)]="os.currentProfile.profileAcceptStripe" (change)="os.toggleProfileAcceptStripe()">Stripe Payment</mat-slide-toggle>

					</mat-expansion-panel>

				</mat-accordion>
			</div>


		</div>

		<div style="width: 500px;">



			<div class="example-container">
				<h2 style="margin: 0;">Available Question</h2>
				<p>Drag and Drop into the profile</p>

				<div
				cdkDropList
				#questionTypeList="cdkDropList"
				[cdkDropListData]="questionType"
				[cdkDropListConnectedTo]="[profileQuestionList]"
				class="example-list"
				(cdkDropListDropped)="drop($event)">
				<div class="example-box" *ngFor="let item of questionType" cdkDrag>

					<div *ngIf="item.type == 'short'">
						<h3 style="margin: 0;padding: 0;">Short text</h3>
					</div>

					<div *ngIf="item.type == 'long'">
						<h3 style="margin: 0;padding: 0;">Long Text</h3>
					</div>

					<div *ngIf="item.type == 'date'">
						<h3 style="margin: 0;padding: 0;">Date</h3>
					</div>

					<div *ngIf="item.type == 'time'">
						<h3 style="margin: 0;padding: 0;">Time</h3>
					</div>

					<div *ngIf="item.type == 'promotion'">
						<h3 style="margin: 0;padding: 0;">Promotion</h3>
					</div>

					<div *ngIf="item.type == 'dropdown'">
						<h3 style="margin: 0;padding: 0;">Dropdown</h3>
					</div>

					<div *ngIf="item.type == 'auth-user'">
						<h3 style="margin: 0;padding: 0;">(Hidden) Authenticated User</h3>
					</div>

					<div *ngIf="item.type == 'location'">
						<h3 style="margin: 0;padding: 0;">Location</h3>
					</div>

					<div *ngIf="item.type == 'whatsapp-send'">
						<h3 style="margin: 0;padding: 0;">(Hidden) WhatsApp Send</h3>
					</div>

					<div *ngIf="item.type == 'rider'">
						<h3 style="margin: 0;padding: 0;">(Hidden) Delivery Rider</h3>
					</div>


				</div>
			</div>
		</div>


	</div>


</div>


<div *ngIf="!editProfile">
	<h3>Questions:</h3>
	<p *ngFor="let question of os.currentProfile.profileQuestion">{{question.field}}</p>
</div>

</div>




</div>


