import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore'; 
import { Observable,Observer } from 'rxjs';
import { ViewServiceService } from '../view-service.service';

@Component({
  selector: 'app-member-component',
  templateUrl: './member-component.component.html',
  styleUrls: ['./member-component.component.scss']
})
export class MemberComponentComponent implements OnInit {

	showSetting:boolean

  constructor(private afs:AngularFirestore, private vs:ViewServiceService) {

  	this.showSetting = false;




  }

  ngOnInit(): void {
  }

  toggleSetting(set:boolean){
  	this.showSetting = set;
  }

}
