import { Pipe, PipeTransform } from '@angular/core';
import { PermissionServiceService } from './permission-service.service';

@Pipe({
  name: 'categoryPermission'
})
export class CategoryPermissionPipe implements PipeTransform {
	constructor(private ps:PermissionServiceService) {

	}
  transform(value: any[]): unknown {

  	console.log(value);
    return null;
  }

}
