import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { UserServiceService } from '../user-service.service';

declare var VANTA;

@Component({
  selector: 'app-login-component',
  templateUrl: './login-component.component.html',
  styleUrls: ['./login-component.component.scss']
})
export class LoginComponentComponent implements OnInit {

	email : string = "";
	password : string = "";

  effect:any;
  @ViewChild('net-background', {static: false}) bgDiv: ElementRef;

  constructor(public us:UserServiceService) { }

  ngOnInit() {
      VANTA.NET({
        el: '#net-background',
        mouseControls: true,
        touchControls: true,
        minHeight: 200.00,
        minWidth: 200.00,
        scale: 1.00,
        scaleMobile: 1.00,
        color: 0xff993f,
        backgroundColor: 0x132f50,
        points: 14.00,
        maxDistance: 21.00
    })
  }

  login(){
  	this.us.login(this.email,this.password)
  }



}
