// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCW5t0k8NLsJ8_tv5aJn20FRmuoiBVKBok",
    authDomain: "happen-project.firebaseapp.com",
    databaseURL: "https://happen-project.firebaseio.com",
    projectId: "happen-project",
    storageBucket: "happen-project.appspot.com",
    messagingSenderId: "288721854786",
    appId: "1:288721854786:web:a5827839bd8c4f67b828db",
    measurementId: "G-GSM37C1F94"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
