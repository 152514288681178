<div style="height: 100%;">
			
		<p-table #dt [value]="memberListArr" [columns]="selectedColums">
			<ng-template pTemplate="caption">
		        <div style="text-align:left">
		             <p-multiSelect
				      [options]="tableColumns"
				      [(ngModel)]="selectedColums"
				      [style]="{width: '100%'}"
				      optionLabel="header"
				      defaultLabel="Choose Columns"
				      selectedItemsLabel="{0} columns selected">
				    </p-multiSelect>
		        </div>
		    </ng-template>
		    <ng-template pTemplate="header" let-columns="selectedColums">
		    <tr>
		      <th *ngFor="let col of selectedColums" [pSortableColumn]="col.field">
		      	{{col.header}}
		      	<p-sortIcon [field]="col.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>


		      </th>
		      
		    </tr>
		  </ng-template>
		  <ng-template pTemplate="body" let-item let-columns="columns">
		    <tr>
				<td *ngFor="let col of columns" [ngSwitch]="col.field">
					<div *ngSwitchCase="'approved'">
						<div style="display: flex;justify-content: space-between;" *ngIf="item[col.field]">
							<p>Approved</p>
							<button mat-raised-button (click)="editApproval(item,false)">Revoke</button>
						</div>
						<div style="display: flex;justify-content: space-between;" *ngIf="!item[col.field]">
							<p>Pending</p>
							<button mat-raised-button (click)="editApproval(item,true)">Approve</button>
						</div>
						
					</div>
					<div *ngSwitchDefault="approved" (click)="editUser(item)">
						{{item[col.field]}}					
					</div>
				</td>


				
		    </tr>
		  </ng-template>