import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderStatusPipe'
})
export class OrderStatusPipePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {

  	if(value == 10){
  		return 'COD cancelled before payment'
  	}
    
    if(value == 11){
    	return 'COD cancelled after payment'
    }

    if(value == 20){
    	return 'Offline Payment cancelled before payment'
    }

    if(value == 21){
      return 'Offline Payment cancelled after payment'
    }

    if(value == 30){
      return 'Stripe cancelled/failed before payment'
    }

    if(value == 31){
      return 'Stripe cancelled after payment'
    }

    if(value == 110){
      return 'COD payment pending'
    }

    if(value == 111){
      return 'COD payment complete, delivery pending'
    }

    if(value == 120){
      return 'Offline payment pending'
    }

    if(value == 121){
      return 'Offline payment complete, delivery pending'
    }

    if(value == 130){
      return 'Stripe payment pending'
    }

    if(value == 131){
      return 'Stripe payment complete, delivery pending'
    }

    if(value == 210){
      return 'COD delivery complete'
    }

    if(value == 220){
      return 'Offline Payment delivery complete'
    }

    if(value == 230){
      return 'Stripe Payment delivery complete'
    }
    
    return null;
  }

}
