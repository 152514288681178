import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { OrderServiceService } from '../order-service.service';
import { MenuServiceService } from '../menu-service.service'
import { PermissionServiceService } from '../permission-service.service';
import { ViewServiceService } from '../view-service.service';
import { AgmMap } from '@agm/core';
import { HttpClient } from '@angular/common/http';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker'

import * as firebase from 'firebase/app';

@Component({
  selector: 'app-order-dialog',
  templateUrl: './order-dialog.component.html',
  styleUrls: ['./order-dialog.component.scss']
})
export class OrderDialogComponent implements OnInit {

	newProfileName:string
	editProfileName:string
  
  editOrder:any
  editOrderProfile:any
  editOrderItem:any

  editOrderTrayStatus:any

  riderArr : any
  selectedVariable:any = 'default'
  variableOpt:any = []

  apiKey = 'AIzaSyCW5t0k8NLsJ8_tv5aJn20FRmuoiBVKBok';
  mapURL = 'https://maps.googleapis.com/maps/api/geocode/json';
  searchAddress:string
  marker:any = {
    position:{
      latitude:'',
      longitude:''
    }
  }

  timepickerTime : any;

  constructor(public dialogRef: MatDialogRef<OrderDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData, public os:OrderServiceService, public ms:MenuServiceService, public ps:PermissionServiceService, private afs:AngularFirestore, private vs:ViewServiceService,private http: HttpClient) {

    this.vs.currentOrgObs.subscribe( org => {
      if(org) {
        this.afs.collection('organization/'+org+'/rider').valueChanges({idField:'riderDocId'}).subscribe( rider => {
          this.riderArr = rider;
        })
      }
    })
  
    if(this.data.operation == "showAvailableVariable"){

      this.variableOpt = [...this.os.currentProfile.profileQuestion];
      console.log('opt',this.variableOpt)

      let riderArr = [];
      let locArr = [];



      for(let question of this.variableOpt){
        console.log(question)
        if(question.type == 'rider'){

          this.variableOpt.splice(this.variableOpt.indexOf(question),1)
          let cleansedRider = [...this.ps.organizationRiderQuestion]
          riderArr = []
          for(let riderInfo of cleansedRider){
            let newRiderInfo = {
              field:'rider' + riderInfo['field'],
              type:'rider'
            }
            riderArr.push(newRiderInfo)
          }
        }else if(question.type == 'location'){

                console.log(question)

          this.variableOpt.splice(this.variableOpt.indexOf(question),1)

          locArr = [
            {
              field:'live_location',
              type:'link'
            },
            {
              field:'formatted_address',
              type:'location'
            },
            {
              field:'position',
              type:'location'
            }

          ]         
        }
      }
      for(let res of riderArr){
        this.variableOpt.push({...res})
      }
      for(let res of locArr){
        this.variableOpt.push({...res})
      }
    }

  	if(this.data.operation == 'updateProfileName'){
  		this.editProfileName = this.os.currentProfile.profileName;
  	}

    if(this.data.operation == 'editOrder'){


      let orderData = this.data.order;
      console.log(orderData)

      this.editOrderTrayStatus = this.data.order.trayStatus

      let responseArr = []

      for(let profile of this.os.orderProfileArr){
        if(orderData.trayProfile == profile.profileName){

          Object.keys(orderData).forEach(key => {

              for(let question of profile.profileQuestion){
                if(question.field.toLowerCase().split(' ').join('') == key.replace('trayOrder','').toLowerCase().split(' ').join('')){

                  let newQuestion;

                  if(question.type == 'whatsapp-send'){
                    newQuestion = question;
                  }else if(question.type == 'location'){
                    newQuestion = orderData[key];

                    this.searchAddress = orderData[key].response.formatted_address
                    this.marker.position.latitude = orderData[key].response.position.latitude
                    this.marker.position.longitude = orderData[key].response.position.longitude

                  }else if(question.type == 'time') {
                    newQuestion = question;
                    delete newQuestion.minTime;
                    delete newQuestion.maxTime;

                    if(orderData[key].includes(', ')) {
                      let substring = orderData[key].split(', ');
                      substring = substring[1].split(' ');
                      let fstring = substring[0].split(':');                      

                      if(substring[1] == "am") {
                        fstring = fstring[0] + ':' + fstring[1];
                        newQuestion['response'] = orderData[key];
                        this.timepickerTime = fstring;

                      } else {
                        let pm = fstring[0];
                        let makeUInt = +pm;
                        let pmstring = makeUInt + 12;
                        fstring = pmstring + ':' + fstring[1];
                        newQuestion['response'] = orderData[key];
                        this.timepickerTime = fstring;
                      }
                      
                    } else {
                      newQuestion['response'] = orderData[key];
                      this.timepickerTime = orderData[key];
                    }
                    
                  } else{
                    newQuestion = question;
                    newQuestion['response'] = orderData[key];
                  }
                    responseArr.push(newQuestion);
                    break;
                }

              }
              
          });

        }
      }

      let editOrderItem = []
      for(let item of orderData.trayItem){
        editOrderItem.push(item)
      }

      for(let res of responseArr){

        if(res.type == 'date'){

          let year = Number(res.response.split('/')[2].split(' ')[0]);
          let month = Number(res.response.split('/')[1]) - 1;
          let date = Number(res.response.split('/')[0]);

          let dateObj = new Date(year, month,date);
          res.response = dateObj;
        }

        if(res.type == 'time'){

          if(res.timeOnly){
            let hour = Number(res.response.split(':')[0]);
            let minute = Number(res.response.split(':')[1].split(' ')[0]);
            let ampm = res.response.split(' ')[1];
            if(ampm == 'PM' && hour != 12){
              hour = hour + 12;
            }

            res.response = hour.toString().padStart(2, '0') + ':' + minute.toString().padStart(2, '0');
          }

        }


      }



      this.editOrder ={
        orderId:orderData.trayOrderId,
        orderProfile:orderData.trayProfile,
        orderResponse:responseArr,
        orderItem:editOrderItem,
        orderRemovedItem:[]
      }


    }

  }

  ngOnInit(): void {
  }

  changeOrderProfile(){

    let selectedProfile = this.editOrder.orderProfile
    let responseArr = []
    let orderData = this.data.order;
    console.log(orderData);

    if(selectedProfile == this.data.order.trayProfile){

      for(let profile of this.os.orderProfileArr){
        if(orderData.trayProfile == profile.profileName){

          Object.keys(orderData).forEach(key => {
            console.log(key);
              for(let question of profile.profileQuestion){
                if(question.field.toLowerCase().split(' ').join('') == key.replace('trayOrder','').toLowerCase().split(' ').join('') ){
                  
                  let newQuestion = question;
                  newQuestion['response'] = orderData[key];
                  responseArr.push(newQuestion);
                }
              }
              
          });

        }
      }

    }else{

      for(let profile of this.os.orderProfileArr){
        if(selectedProfile == profile.profileName){

          for(let question of profile.profileQuestion){
              let newQuestion = question;
              newQuestion['response'] = '';
              responseArr.push(newQuestion);
          }
        }
      }

    }

    this.editOrder['orderResponse'] = responseArr

  }

  editOrderRemoveItem(index){
    this.editOrder.orderRemovedItem.push(this.editOrder.orderItem[index])
    this.editOrder.orderItem.splice(index,1);
  }

  updateOrder(){

    for(let res of this.editOrder.orderResponse){



        if(res.type == 'date'){
          res.response = res.response.toISOString();
          res.type = 'date';
        }

        if(res.type == 'time' && res.timeOnly == false) {
          console.log(res.response);
          let date = res.response.split(',')[0]

          let month = date.split('/')[0]
          let day = date.split('/')[1]
          let year = date.split('/')[2]

          let dateString = day+'/'+month+'/'+year;
          let timeString = res.response.split(',')[1];
          let datetime = dateString + " " + timeString + " UTC";

          let obj = new Date(datetime);
          res.response = this.timepickerTime;
          obj.setUTCHours(res.response.split(':')[0])
          obj.setUTCMinutes(res.response.split(':')[1])

          res.response = obj.toISOString().replace('Z', '')+"+08:00";
          res.type = 'time';
        }

        if(res.type == 'location'){
          res['response'] = {
            formatted_address:this.searchAddress,
            position:new firebase.firestore.GeoPoint(this.marker.position.latitude, this.marker.position.longitude)
          }
        }


      }

      console.log(this.editOrder.orderResponse)

      let operation = "completed";
      if(!this.editOrderTrayStatus) {
        operation = "pending";
      } else if(this.editOrderTrayStatus == "cancelled"){
        operation = "cancelled";
      }

    this.os.updateOrder(this.editOrder,operation,this.data.order)
  }

  onNoClick(): void {
    this.dialogRef.close(this.selectedVariable.field);
  }

requestGeoAPI() {
  this.http.get(this.mapURL + '?address=' + encodeURIComponent(this.searchAddress) + '&key=' + this.apiKey).subscribe( (data:any) =>{

    console.log(data)

    this.marker = Object.create({
      position:{
        latitude:data.results[0].geometry.location.lat,
        longitude:data.results[0].geometry.location.lng
      }
    })

    this.searchAddress = data.results[0].formatted_address

  });
}

}



export interface DialogData{
	operation:string,
  order:any,
  variable:string
}