<!-- <mat-toolbar>

	<span>
		<mat-form-field>
			<mat-label>Organization</mat-label>
			<mat-select [(ngModel)]="vs.viewOrg">
				<mat-option *ngFor="let org of ps.allowedOrganization" [value]="org">
					{{org}}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</span>

	<span class="example-fill-remaining-space"></span>

	<span>
		<button (click)="us.logout()">Sign Out</button>
	</span>



</mat-toolbar> -->

<mat-toolbar>

    <span>
    	<mat-form-field>
    	<mat-select [(ngModel)]="vs.viewOrg">
				<mat-option *ngFor="let org of ps.allowedOrganization" [value]="org">
					{{org}}
				</mat-option>
			</mat-select>
		</mat-form-field>
    </span>
    <span class="example-fill-remaining-space"></span>

    <span *ngIf="receivedOrderDisplay">
    	<div style="font-size: 5vh; display: flex; justify-content: center; padding-top: 10px;">
    		{{os.totalOrderNumber}}
    	</div>

    	<div style="font-size: 1.5vh; display: flex;">
    		Received Order
    	</div>

    	

    	<!-- <div style="font-size: 0.5rem; display: flex;">
    		Maximum Order : {{os.orderLimit}}
    	</div> -->

    	<span style="padding-left: 10px;">
    		<button mat-raised-button>TopUp</button>
    	</span>
    	
    </span>

    

    <span class="example-fill-remaining-space"></span>
    <span>
    	<button mat-button (click)="us.logout()">Sign Out</button>
    </span>
</mat-toolbar>



<!-- <div>
	<button *ngFor="let org of ps.allowedOrganization" (click)="vs.switchOrg(org)">{{org}}</button>
</div>
 -->
<!-- <div style="display: flex;">

	<div style="width: 50%">
		<router-outlet></router-outlet>
	</div>
		
	<app-order-component></app-order-component>
	<app-menu-component></app-menu-component>

</div>

 -->
