<!DOCTYPE html>
<html>
<head>
  <title></title>
</head>
<body>

<router-outlet></router-outlet>

</body>
</html>