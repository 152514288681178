<div style="display: flex;flex-direction: column;width: 100%;padding: 10px;">
	<div>
		<h1 *ngIf="ms.checkViewCategory">{{ms.currentCategoryName}}</h1>
		<button *ngIf="ms.checkCreateItem" mat-raised-button (click)="createItem()">Create Item</button>
	</div>

<!-- <br><br>
<hr>
<ol>
	<li *ngFor="let item of ms.itemList | async" >
		{{item.itemName}}
		{{item.itemPrice}}
		{{item.itemDesc}}
		{{item.itemCategory}}
		{{item.itemTag}}
	</li>
</ol> -->

<div style="display: flex;flex-wrap: wrap;">
	<div *ngIf="ms.checkViewItem" style="max-width: 100%; display: flex;flex-wrap: wrap;">
		<div *ngFor="let item of ms.itemFilterArr" style="padding: 0.25rem;">
			<mat-card class="example-card" style="width: 200px;height: 250px;text-align: center;" (click)="itemDetailShow(item.itemId)">
	<!-- 		  <mat-card-header style="margin: 0px;">
	 -->		    <!-- <div mat-card-avatar class="example-header-image"></div> -->
	<!-- 		    <mat-card-title style="margin: 0px; font-size: 16px; text-align: center;"></mat-card-title> -->
			    <!-- <mat-card-subtitle>Dog Breed</mat-card-subtitle> -->
	<!-- 		  </mat-card-header>
	 -->		  
	 	<img mat-card-image src="{{item.itemURL}}" alt="{{item.itemName}}" style="height: 200px;width: 200px;object-fit: cover;">
	 				<p style="margin: -16px;">{{item.itemName}}</p>
	<!-- 		  <mat-card-content>
			    <p>
			      {{item.itemDesc}}
			    </p>
			  </mat-card-content> -->
			  <!-- <mat-card-actions>
			  	<mat-icon (click)="updateItem(item.itemId)" aria-hidden="false">edit</mat-icon>
			  	<mat-icon (click)="deleteItem(item.itemId)" aria-hidden="false">delete</mat-icon>
			  </mat-card-actions> -->
			</mat-card>
			
		</div>
	</div>

	
</div>

</div>

