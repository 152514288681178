


<div id="net-background" style="width: 100%;height: 100%;display: flex;justify-content: center;align-items: center; flex-direction: column;">

	<div>

		<h1 style="font-size: 3rem;color: white;font-weight: 900;padding-bottom: 15px;">Login</h1>

		<mat-card>
			

		<mat-form-field class="example-full-width">
			<mat-label>Email</mat-label>
			<input matInput placeholder="Email" [(ngModel)]="email" >
		</mat-form-field>

		<mat-form-field class="example-full-width">
			<mat-label>Password</mat-label>
			<input matInput placeholder="Password" [(ngModel)]="password" type="password">
		</mat-form-field>

		<button mat-raised-button color="primary" (click)="login()">Login</button>&nbsp;

		<h6>Copyright © 2020 Round Midknight Development Team</h6>
		</mat-card>
	</div>

	
</div>


