import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import { Route, Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { PermissionServiceService } from './permission-service.service';

@Injectable({
  providedIn: 'root'
})
export class UserServiceService {
	currentUser:any

  constructor(
    public auth: AngularFireAuth,
    private router: Router,
    private route: ActivatedRoute,
    private afs:AngularFirestore,
    private ps:PermissionServiceService
    ) {

    

  	let authOb = this.auth.authState

    authOb.subscribe( data => {
      this.currentUser = data
      this.ps.fetchPermission(this.currentUser.uid)
    })



  }


  login(email,password) {
    this.auth.signInWithEmailAndPassword(email, password)
	.then(res => {
    window.location.href = "/panel";
		// this.router.navigate(['panel/']);
	})
	.catch(err => {
		
	});
  }


  logout() {
    this.auth.signOut().then( ()=> {
    	window.location.href = "/"
    });
  }

}
