import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore'; 
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { CategoryDialogComponent } from '../category-dialog/category-dialog.component';
import { CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { MenuServiceService } from '../menu-service.service'
import { Category } from '../menu-service.service';
import { CategoryPermissionPipe } from '../category-permission.pipe';

@Component({
  selector: 'app-category-component',
  templateUrl: './category-component.component.html',
  styleUrls: ['./category-component.component.scss']
})

export class CategoryComponentComponent implements OnInit {

  checkViewCategory : boolean = false;

  constructor(private storage: AngularFireStorage, private afs:AngularFirestore, public dialog:MatDialog, public ms:MenuServiceService) {
  }

  ngOnInit() { 
  }

  createCategory() {
  	this.dialog.open(CategoryDialogComponent, {
  		width:'250px',
  	});
  }

  updateCategory(id : string, name : string, status : boolean, tray : any) {
    this.dialog.open(CategoryDialogComponent, {
      width:'250px',
      data : {
          categoryMethod : "update",
          categoryId : id,
          categoryName : name, 
          categoryStatus : status,
          categoryTray : tray,
      }

    })
  }

  deleteCategory(id : string, name : string) {
    this.dialog.open(CategoryDialogComponent, {
      width:'250px',
      data : {
          categoryMethod : "delete",
          categoryName : name,
          categoryId : id,
      }

    })
  }

  updateCategoryIndex() {
    for(var i=0;i<this.ms.categoryArr.length;i++){

      this.afs.doc('outlet/'+this.ms.currentOutlet+'/category/' + this.ms.categoryArr[i].categoryId).update({
        categoryIndex: i
      })

    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.ms.categoryArr, event.previousIndex, event.currentIndex);
    this.updateCategoryIndex();
  }

  filterCategory(cat : string, name : string) {
    // this.ms.currentCategory = cat;
    this.ms.currentCategoryName = name;
    this.ms.filterItem(cat);
  }

}
