import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable,throwError } from 'rxjs';
import { OrderServiceService } from '../order-service.service';
import { UncleanseFieldPipe } from '../uncleanse-field.pipe'
import { CompletePipePipe } from '../complete-pipe.pipe';
import { StatusPipePipe } from '../status-pipe.pipe';
import { RiderIdToNamePipe }from '../rider-id-to-name.pipe';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderDialogComponent } from '../order-dialog/order-dialog.component';
import { PermissionServiceService } from '../permission-service.service';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import { ViewServiceService } from '../view-service.service';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { PrintDialogComponent } from '../print-dialog/print-dialog.component';


@Component({
  selector: 'app-order-tray',
  templateUrl: './order-tray.component.html',
  styleUrls: ['./order-tray.component.scss']
})
export class OrderTrayComponent implements OnInit {

	view:string
	gridSearch:string

	////Permissions
	completeOrder:boolean
 	editOrderDetails:boolean
    
    riderArr : any;
    orgId : string;

	constructor(private http: HttpClient, private afs:AngularFirestore, public os:OrderServiceService,public dialog:MatDialog, public ps:PermissionServiceService, private vs:ViewServiceService) {
		this.view = 'grid'

		let firstSub = this.os.loadFirstTraySub.subscribe( data => {
			if(data['trayName'] != ''){
				this.viewTray(data)
				firstSub.unsubscribe()
			}
		})


		this.vs.currentOrgObs.subscribe( org => {
	      if(org) {
	      	this.orgId = org;
	        this.afs.collection('organization/'+org+'/rider').valueChanges({idField:'riderDocId'}).subscribe( data => {
	          this.riderArr = data;
	        })
	      }
	    })

	}

	ngOnInit(): void {
	}

	togglePanel(panel : any, order : any, selected : any) {
		panel.close();

		let riderGet = this.afs.doc('order/'+order.trayOrderId).valueChanges().subscribe( (data:any) => {
			let newOrderResponse = data.orderResponse;
			for(var i=0; i<data.orderResponse.length; i++) {
				if(data.orderResponse[i].type == "rider") {
					newOrderResponse[i].response = selected;
				}
			}

			riderGet.unsubscribe();
			this.afs.doc('order/'+order.trayOrderId).update({
				orderResponse:newOrderResponse,
			});

			let riderNotifictation = this.afs.doc('organization/' + this.orgId + '/rider/' + selected).valueChanges().subscribe( (riderDoc:any) => {

				let body = {
					token: riderDoc.riderToken
				}

				riderNotifictation.unsubscribe();

			  	this.http.post<any>('https://us-central1-happen-project.cloudfunctions.net/newTrip', body).subscribe( data => {
			  		console.log(data)
			  	});


			})

		})


	}

	toggleDisplay(view){
		this.view = view;
	}

	viewTray(tray){
		this.os.viewTray(tray)
		///Permissions
    	this.completeOrder = this.ps.checkPermission('ordertray.' + tray.trayId + '.completeOrder')
    	this.editOrderDetails = this.ps.checkPermission('ordertray.' + tray.trayId + '.editOrderDetails')
	}

	editOrder(order){
		this.dialog.open(OrderDialogComponent, {
		  width: '500px',
		  data: {
		    operation:'editOrder',
		    order:order
		  }
		})
	}

    filterDate(type: string, event: MatDatepickerInputEvent<Date>){

     this.os.fetchOrder(this.os.currentTray)

   }

   printOrder(order){

 //   	this.dialog.open(PrintDialogComponent, {
	//   width: '500px',
	//   data: {
	//     order:order
	//   }
	// })

	let body = {
		order: order
	}

  	this.http.post<any>("http://localhost:8443", body).subscribe( data => {
  		console.log(data)
  	});

   }




}

