<div style="display: flex;height: 100%;">
	
	<div style="min-width: 200px;background: white;height: 100%">
		
		<mat-list role="list" style="width: 100%">

		  <mat-list-item *ngIf="checkViewRider" (click)="toggleSetting('list')">Rider</mat-list-item>
		  <mat-list-item *ngIf="checkDeliveryConfig" (click)="toggleSetting('config')">Settings</mat-list-item>
		  <mat-list-item *ngIf="checkCheckInQR" (click)="toggleSetting('check-in')">Check In</mat-list-item>
		  <mat-list-item *ngIf="checkViewMap" (click)="toggleSetting('map')">Map</mat-list-item>

		</mat-list>



	</div>
	<div style="width: 100%;">

		<app-delivery-list *ngIf="showComponent == 'list'"></app-delivery-list>
		<app-delivery-config *ngIf="showComponent == 'config'"></app-delivery-config>
		<app-delivery-checkin *ngIf="showComponent == 'check-in'"></app-delivery-checkin>
		<app-delivery-map *ngIf="showComponent == 'map'"></app-delivery-map>

	</div>




</div>




