import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-order-component',
  templateUrl: './order-component.component.html',
  styleUrls: ['./order-component.component.scss']
})
export class OrderComponentComponent implements OnInit {

  constructor() {

  }

  ngOnInit(): void {
  }


}
