import { Injectable, Input, EventEmitter, Output } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable, Observer, throwError} from 'rxjs';
import { FilterUtils } from 'primeng/utils';
import { OrderFilterPipe } from './order-filter.pipe'
import { CategoryFilterPipe } from './category-filter.pipe';
import { MenuServiceService } from './menu-service.service';
import { PermissionServiceService } from './permission-service.service';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrderServiceService {

  currentOrg:string
  currentOrgObs:Observable<string>

  loadFirstTraySub:Observable<any>

  orderProfileCollection:AngularFirestoreCollection<OrderProfile>
  orderProfile:Observable<OrderProfile[]>
  orderProfileArr:OrderProfile[]
  orderProfileOption:any[]

  currentProfile:OrderProfile

  orderTrayCollection:AngularFirestoreCollection<Tray>
  orderTrays:Observable<Tray[]>
  orderTrayBadgeArr:any
  currentTray:Tray
  currentTrayOrder:any[]


  orderCollection:AngularFirestoreCollection<Order>
  orders:Observable<Order[]>
  sortedOrder:any[]
  orderTableCol:any[]

  selectedColumns: any[];

  viewComplete:boolean
  viewIncomplete:boolean
  viewCancelled:boolean
  orderStatusOption:any[]

  lowerDate:any
  upperDate:any

  dayString = ['Mon','Tue','Wed','Thu','Fri','Sat','Sun'];
  livelocationWebURL:string

  acknowledgedOrder:any
  orderIncomplete:boolean
  @Output() gotNewIncomplete = new EventEmitter();

  totalOrderNumber : number = 0;
  orderLimit : number = 0;
  reachLimitToggle : boolean = false;
  progressBarValue : number = 0;
  progressBarColor : any;

  codCheck : boolean = false;
  offlineCheck : boolean = false;
  stripeCheck : boolean = false;
  bank:string;
  accountName:string;
  accountNumber:string;
  codIndex:any;
  offlineIndex:any;
  stripeIndex:any;

  constructor(private http: HttpClient, private afs:AngularFirestore, private ms:MenuServiceService, private ps:PermissionServiceService) {

    this.lowerDate = new Date(Date.now());
    this.upperDate = new Date(Date.now());

    this.currentProfile = {
      profileId:'',
      profileName:'',
      profileQuestion:[],
      profileStatus:false,
      profileAuth:false,
      profileAcceptStripe:false
    }

    this.orderTrayBadgeArr = {};

    this.currentTray = {
      trayName:'',
      trayId:'',
      trayOrganization:''
    }

    this.currentOrgObs = Observable.create( (observer:Observer<string>) => {

      let interval = setInterval(() => {

        observer.next(this.currentOrg)
        if(this.currentOrg){
          clearInterval(interval)
          observer.complete()
        }
        
      }, 100);      

    })

    this.loadFirstTraySub = Observable.create( (observer:Observer<any>) => {

      let interval = setInterval(() => {

        observer.next(this.currentTray)
        if(this.currentTray.trayName != ''){
          clearInterval(interval)
          observer.complete()
        }
        
      }, 100);      

    })

    this.viewComplete = false;
    this.viewIncomplete = true;
    this.viewCancelled = false;

    this.sortedOrder = []

    FilterUtils['custom'] = (value, filter): boolean => {
      if (filter === undefined || filter === null || filter.trim() === '') {
        return true;
      }

      if (value === undefined || value === null) {
        return false;
      }
      
      return parseInt(filter) > value;
    }

    this.orderStatusOption = [
    {
      label:'All Status',
      value:null
    },
    {
      label:'Pending',
      value:false
    },
    {
      label:'Completed',
      value:true
    },
    {
      label:'Cancelled',
      value:'cancelled'
    }
    ];


    this.currentOrgObs.subscribe( org => {    //// TO FETCH CURRENT APP LIVE LOCATION

      if(org){
        this.afs.doc('organization/' + org + '/apps/mvh-retail').valueChanges().subscribe( (orgDoc:any) => {     
          if(orgDoc){
            this.livelocationWebURL = orgDoc['webURL'] + '/live-location';
          }
        })
      }

    })

  }



  fetchOrderProfile(org){



    this.currentOrg = org

    this.afs.doc('organization/' + this.currentOrg + '/package/limit').valueChanges().subscribe( (limit:any) => {
      // console.log(limit);
      this.orderLimit = limit.order;
    })

    this.orderProfileCollection = this.afs.collection<OrderProfile>('order-profile' , ref => ref.where('profileOrganization','==',this.currentOrg));
    this.orderProfile = this.orderProfileCollection.valueChanges({idField:'profileId'})

    this.orderProfile.subscribe( data =>{

      if(data[0]){
        this.viewProfile(data[0])
      }
      this.orderProfileArr = data

      this.orderProfileOption = [
      { label: 'All Profile', value: null },
      ]
      for(let op of data){

        this.orderProfileOption.push({
          label:op.profileName,
          value:op.profileName
        })
      }

    })



    this.orderTrayCollection = this.afs.collection<Tray>('order-tray' , ref => ref.where('trayOrganization','==',this.currentOrg));
    this.orderTrays = this.orderTrayCollection.valueChanges({idField:'trayId'})

    this.acknowledgedOrder = []

    this.orderTrays.subscribe( data => {
      //assign to view the first tray automatically when loading
      this.currentTray = data[0]

      // console.log(data);
      this.orderIncomplete = false;
      for(let tray of data){

        if(this.ps.checkPermission('ordertray.' + tray.trayId + '.viewTray')){


          this.afs.doc('organization/' + this.currentOrg).valueChanges().subscribe( (data:any) => {

            // console.log(data);

            this.totalOrderNumber = data.orderTotalAmount;
            this.reachLimitToggle = !data.organizationStatus;

            let percentage = (this.totalOrderNumber/this.orderLimit)*100;
            this.progressBarValue = percentage;

            if(percentage >= 50 && percentage < 75) {
              this.progressBarColor = "warn";
            }
            else if(percentage >= 75 && percentage < 100) {
              this.progressBarColor = "accent";
            }
            else if(percentage == 100) {
              this.progressBarColor = "accent";
            }
            else {
              this.progressBarColor = "primary";
            }

          })

          let incompleteOrderSub = this.afs.collection('order', ref => ref.where('orderStatus','>=',110).where('orderStatus', '<', 200).where('orderTray','array-contains',tray.trayId)).valueChanges({idField:'orderId'}).subscribe( (orderData:any) => {

            for(let order of orderData){
              let involvedTrays = order.orderTray;
              // console.log(involvedTrays);

              let allTrayComplete = true;

              for(let itray of involvedTrays){
                if(!order[itray].trayStatus){

                  allTrayComplete = false;
                  // console.log(this.acknowledgedOrder,orderData.length)

                  if(!this.acknowledgedOrder.includes(order.orderId)){
                    this.acknowledgedOrder.push(order.orderId)
                    this.orderIncomplete = true;

                    if(this.acknowledgedOrder.length == orderData.length){
                      this.gotNewIncomplete.emit(true)
                    }

                  }
                }
              }

              incompleteOrderSub.unsubscribe();

              if(allTrayComplete){
                this.afs.doc('order/' + order.orderId).update({
                  orderStatus:true
                })
              }

            }

          })


          this.afs.collection('order', ref => ref.where('orderTray','array-contains',tray.trayId).where('orderStatus','>=',100).where('orderStatus','<',200)).valueChanges().subscribe( (data:any) => {

            this.orderTrayBadgeArr[tray.trayId] = 0;

            for(let order of data){
              if(order[tray.trayId]['trayStatus'] == false){
                this.orderTrayBadgeArr[tray.trayId] = this.orderTrayBadgeArr[tray.trayId] + 1;
              }
            }

            //console.log(tray.trayId,this.orderTrayBadgeArr[tray.trayId]);

          })
        }
      }


      for(let order of data) {
        //      console.log('tryay', order)          
        //// completed order constructor
        this.afs.collection('order', ref => ref.where('orderStatus', '==', true)).valueChanges({idField:'orderDocId'}).subscribe( data => {
          //          console.log('data', data);
        })
      }
      



    })


    
    this.ms.fetchOrderTray(this.orderTrays);

  }

  addNewProfile(name){

  	this.afs.collection('order-profile').add({
  		profileName:name,
  		profileQuestion:[],
  		profileStatus:true,
      profileOrganization:this.currentOrg
    })

  }

  viewProfile(profile){
  	this.currentProfile = profile
    this.viewPaymentMethod();
  }

  updateProfileName(editProfileName){    
    this.afs.doc('order-profile/' + this.currentProfile.profileId).update({
      profileName:editProfileName
    })
  }

  toggleProfileActive(){

  	this.afs.doc('order-profile/' + this.currentProfile.profileId).update({
  		profileStatus:this.currentProfile.profileStatus
  	})

  }

  toggleProfileAuth(){

  	this.afs.doc('order-profile/' + this.currentProfile.profileId).update({
  		profileAuth:this.currentProfile.profileAuth
  	})

  }

  toggleProfileAcceptStripe(){

    this.afs.doc('order-profile/' + this.currentProfile.profileId).update({
      profileAcceptStripe:this.currentProfile.profileAcceptStripe
    })

  }

  updateProfileQuestion(){
  	this.afs.doc('order-profile/' + this.currentProfile.profileId).update({
  		profileQuestion:this.currentProfile.profileQuestion
  	})


    let paymentMethod = [];

    if (this.codCheck) {
      let map = {
        pmId: 'cod',
        pmDisplay: 'Cash On Delivery',
        pmIndex: this.codIndex,
      }
      paymentMethod.push(map);
    }
    if(this.offlineCheck) {
      let map = {
        pmId: 'offline',
        pmDisplay: 'Bank Transfer',
        pmIndex: this.offlineIndex,
        pmBank: this.bank,
        pmAccountName: this.accountName,
        pmAccountNumber: this.accountNumber,

      }
      paymentMethod.push(map);

    }
    if(this.stripeCheck) {
      let map = {
        pmId: 'stripe',
        pmDisplay: 'Credit/Debit Card',
        pmIndex: this.stripeIndex,
      }
      paymentMethod.push(map);
    }

    console.log("payment method", paymentMethod);

    
    this.afs.doc('order-profile/' + this.currentProfile.profileId).update({
      profilePaymentMethod:paymentMethod,
    })



  }

  //////payment method fetch database

  viewPaymentMethod() {
    this.afs.doc('order-profile/' + this.currentProfile.profileId).valueChanges().subscribe((data:any) => {
      this.codCheck = false;
      this.offlineCheck = false;
      this.stripeCheck = false;

      for(var i=0; i<data.profilePaymentMethod.length; i++) {
        if(data.profilePaymentMethod[i].pmId == "cod") {
          this.codCheck = true;
          this.codIndex = data.profilePaymentMethod[i].pmIndex;
        } 
        if(data.profilePaymentMethod[i].pmId == "offline") {
          this.offlineCheck = true;
          this.bank = data.profilePaymentMethod[i].pmBank;
          this.accountName = data.profilePaymentMethod[i].pmAccountName;
          this.accountNumber = data.profilePaymentMethod[i].pmAccountNumber;
          this.offlineIndex = data.profilePaymentMethod[i].pmIndex;
        }
        if(data.profilePaymentMethod[i].pmId == "stripe") {
          this.stripeCheck = true;
          this.stripeIndex = data.profilePaymentMethod[i].pmIndex;
        }
      }
    })
  }

  //////


  viewTray(tray){
    this.currentTray = tray
    this.fetchOrder(tray);
  }


  //////////////

  fetchOrder(tray){

    let targetDay = this.upperDate
    targetDay.setHours(23,59,59,59)
    let previousDay = this.lowerDate
    previousDay.setHours(0,0,0,0)

    

    this.orderCollection = this.afs.collection<Order>('order', ref => ref.where('orderTray','array-contains',tray.trayId).where('orderTimestamp','<',targetDay).where('orderTimestamp','>',previousDay))
    this.orders = this.orderCollection.valueChanges({idField:'orderId'})

    this.orders.subscribe((data:any) => {

      if(data){

        this.sortedOrder[tray.trayName] = []
        let categoryNames = []
        let rawField = []

        for(let order of data){

          let trayOrder = {
            trayOrderId:order.orderId,
            trayItem:[],
            trayTimestamp:new Date(order.orderTimestamp.seconds * 1000)
          }

          if(order[tray.trayId]['trayStatus'] == 'cancelled'){
            trayOrder['trayStatus'] = 'cancelled'
          }else{
            if(order[tray.trayId]['trayStatus']){
              trayOrder['trayStatus'] = true
            }else{
              trayOrder['trayStatus'] = false
            }            
          }


          for(let profile of this.orderProfileArr){
            if(order.orderProfile == profile.profileId){
              trayOrder['trayProfile'] = profile.profileName
            }
          }

          if(order[tray.trayId]['trayItem']){
            for(let trayItem of order[tray.trayId]['trayItem']){
              trayOrder.trayItem.push(trayItem)
            }
          }


          for(let res of order.orderResponse){

            if(!rawField.includes(res.field)){
              rawField.push(res.field)
            }

            let cleanseField = res.field
            cleanseField = 'trayOrder'+cleanseField

            cleanseField = cleanseField.split(' ').join('')

            if(res.type == 'date'){

              let dateObj = new Date(res.response);
              let dateString = dateObj.getDate() + '/' + (dateObj.getMonth() + 1) + '/' + dateObj.getFullYear() + ' ' + this.dayString[dateObj.getDay() - 1]

              trayOrder[cleanseField] = dateString;

            }else if(res.type == 'time'){
              if(res.timeOnly){
                let dateObj =  new Date(Date.now())
                dateObj.setHours(res.response.split(':')[0])
                dateObj.setMinutes(res.response.split(':')[1])
                trayOrder[cleanseField] = dateObj.toLocaleTimeString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' });
              }else{
                let dateObj =  new Date(res.response);
                dateObj.setSeconds(0);
                trayOrder[cleanseField] = dateObj.toLocaleString('en-GB', { hour12: false});
                // console.log(trayOrder[cleanseField])
              }

            }else if(res.type == 'rider') {
              if(res.response){
                trayOrder[cleanseField] = res.response;
              }else{
                trayOrder[cleanseField] = '-';
              }

            }else if(res.type == 'location') {
              trayOrder[cleanseField] = {
                field:res.field,
                response:{
                  formatted_address:res.response.formatted_address,
                  position:res.response.position,
                },                  
                type:'location'
              }

            }
            else if(res.type == 'whatsapp-send'){

              for(let profile of this.orderProfileArr){

                if(profile.profileId == order.orderProfile){
                  for(let question of profile.profileQuestion){
                    if(question.field == res.field){
                      trayOrder[cleanseField] = question
                    }
                  }
                }
              }
            } else {
              trayOrder[cleanseField] = res.response
            }


            ///// put orderStatus inside trayOrder so that all trayOrder got orderStatus
            trayOrder['orderStatus'] = order.orderStatus;
            //////

          }

          this.sortedOrder[tray.trayName].push(trayOrder)
          
        }

        

        this.currentTrayOrder = this.sortedOrder[tray.trayName] 


        this.orderTableCol = [
        { field: 'trayTimestamp', header: 'Timestamp' },
        { field: 'trayProfile', header: 'Type'},
        { field: 'trayItem',header:'Item'},
        { field: 'trayStatus', header:'Status'}
        ];         


        for(let field of rawField){          
          let cleanseField = field
          cleanseField = 'trayOrder'+cleanseField
          cleanseField = cleanseField.split(' ').join('')

          this.orderTableCol.push({
            field:cleanseField,
            header:field
          })
        }

        this.selectedColumns = this.orderTableCol;
      }

      


    })






}

editOrderStatus(order,operation){

  ///let's grab the tray list first

  let orderCollection = this.afs.doc('order/' + order.trayOrderId)

  if(operation == 'completed' || operation == 'cancelled'){

    let orderObs = orderCollection.valueChanges().subscribe( (data:any) => {
      orderObs.unsubscribe()

      let trayList = data.orderTray

      let trayArr = data[this.currentTray.trayId]
      if(operation == 'completed'){
        trayArr.trayStatus = true;
      }else{
        trayArr.trayStatus = 'cancelled';
      }


      orderCollection.update({
        [this.currentTray.trayId]:trayArr
      }).then( () => {

        let allDone = true

        for(let trayId of trayList){
          if(data[trayId].trayStatus == false){
            allDone = false
          }
        }

        if(allDone){
          orderCollection.update({
            orderStatus:true
          })
        }

      })




    })      
  }

  if(operation == 'pending'){

    let orderCollection = this.afs.doc('order/' + order.trayOrderId)
    let orderObs = orderCollection.valueChanges().subscribe( (data:any) => {

      let trayList = data.orderTray

      let trayArr = data[this.currentTray.trayId]
      trayArr.trayStatus = false;

      orderObs.unsubscribe()

      orderCollection.update({
        orderStatus:false
      })

      orderCollection.update({
        [this.currentTray.trayId]:trayArr
      })

    })


  }



}

updateOrder(order, op, orderdata){

  let fetchSub = this.afs.doc('order/' + order.orderId).valueChanges().subscribe( (data:any) => {
    fetchSub.unsubscribe()
    ////let's process item first

    let updatedTray = []
    let allTray = data.orderTray
    let riderDocId;

    for(let tray of allTray){

      let operation : any = data[tray].trayStatus;

      if(this.currentTray.trayId == tray) {

        operation =  true;
        if(op == "pending") {
          operation = false;
        } else if(op == "cancelled"){
          operation = "cancelled";
        }
      }

      let trayItem = data[tray].trayItem

      for(let i=0;i<trayItem.length;i++){
        for(let rmItem of order.orderRemovedItem){

          if(trayItem[i].itemName == rmItem.itemName){
            trayItem.splice(i,1)
          }
        }
      }

      //then we process the response

      let cleansedRes = []

      for(let res of order.orderResponse){
        if(res.type == 'rider'){
          riderDocId = res.response
        }
        cleansedRes.push(res)
      }

      // then get the profile id

      let newProfileId = ''

      for(let profile of this.orderProfileArr){
        if(order.orderProfile == profile.profileName){
          newProfileId = profile.profileId
          break;
        }
      } 


      this.afs.doc('order/' + order.orderId).update({
        [tray]:{
          trayItem:trayItem,
          trayStatus:operation,
        },
        orderResponse:cleansedRes,
        orderProfile:newProfileId
      }).then( ()=> {

        this.editOrderStatus(orderdata,op)

      })

      let riderNotifictation = this.afs.doc('organization/' + this.currentOrg + '/rider/' + riderDocId).valueChanges().subscribe( (riderDoc:any) => {

        let body = {
          token: riderDoc.riderToken
        }

        riderNotifictation.unsubscribe();

        this.http.post<any>('https://us-central1-happen-project.cloudfunctions.net/newTrip', body).subscribe( data => {

        });


      })




    }

  })



}

sendWhatsapp(order,receipient,wafield,riderInfo){

  let phoneNumber;

  for(let profile of this.orderProfileArr){
    if(profile.profileName == order.trayProfile){
      for(let question of profile.profileQuestion){
        if(question.field == wafield){

          let template = question.message;


          Object.keys(order).forEach( key => {

            if(key.replace('trayOrder', '') == receipient){
              phoneNumber = order[key]
            }

            if(key == 'trayOrderDeliveryRider'){
              let riderId = order[key];

              if(riderId == ''){
                alert('Please assign rider!')
                return;
              }

              for(let rider of riderInfo){

                if(rider.riderDocId == riderId){

                  Object.keys(rider).forEach(riderKey => {
                    let targetRiderVar = "<<" + riderKey + ">>";
                    template = template.replace(targetRiderVar,rider[riderKey])

                  })
                }
              }

            }

            if(order[key].type == "location"){
              template = template.replace('<<formatted_address>>',order[key].response.formatted_address)
              template = template.replace('<<position>>','lat:'+order[key].response.position.latitude+',lng:'+order[key].response.position.longitude)
            }

            let targetVariable = "<<" + key.replace('trayOrder','') + ">>";
            template = template.replace(targetVariable,order[key])


          })

          template = template.replace('<<live_location>>',  this.livelocationWebURL + '?ord=' + order.trayOrderId + '&org=' + this.currentOrg)

          phoneNumber = phoneNumber.replace("+",'');

          if(phoneNumber[0] == 0){
            phoneNumber = "6" + phoneNumber
          }

          let urlString = "https://api.whatsapp.com/send?phone=" + phoneNumber + "&text=" + encodeURIComponent(template);

          window.open(urlString, '_blank')

        }
      }
    }
  }
}
}

export interface Outlet{
	outletId:string
	outletName:string
	outletPermission:string[]
}

export interface OrderProfile{
	profileId:string
	profileName:string
	profileQuestion:any[]
	profileStatus:boolean
	profileAuth:boolean
  profileAcceptStripe:boolean
}

export interface Order{
  orderDocId:string
  orderId:string
  orderTimestamp:any
  orderItem:any[]
  orderResponse:any[]
  orderStatus:boolean
  orderProfile:string
}
export interface Tray{
  trayId:string
  trayName:string
  trayOrganization:string
}