import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore'; 
import { Observable,Observer } from 'rxjs';
import { ViewServiceService } from '../view-service.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MemberDialogComponent } from '../member-dialog/member-dialog.component'

@Component({
  selector: 'app-member-list',
  templateUrl: './member-list.component.html',
  styleUrls: ['./member-list.component.scss']
})
export class MemberListComponent implements OnInit {

  	memberListArr:any[]
  	currentOrg:string

  	tableColumns:any[]
  	selectedColums:any[]

  	profileQuestion:any


  constructor(private afs:AngularFirestore, private vs:ViewServiceService,public dialog:MatDialog) {
  	this.fetchMember();
  }

  fetchMember(){

  	this.vs.currentOrgObs.subscribe( data=> {

  		if(data){
  			this.currentOrg = data;
		  	this.afs.collection('organization/' + data + '/members').valueChanges({idField:'memberDocId'}).subscribe( (data:any) => {

		  		this.memberListArr = []

		  		for(let allMember of data){

		  			let newMember = allMember;
		  			newMember["approved"] = false;
		  			this.memberListArr.push(newMember)

		  			this.afs.collection('permission', ref=>ref.where('groupMember','array-contains',allMember.memberUID)).valueChanges().subscribe( (data:any) => {

		  				let approved:boolean = false;

		  				for(let group of data){
		  					if(group.groupPermission.includes(this.currentOrg + '.web.viewWeb')){
		  						for(let pushedMem of this.memberListArr){
				  					if(pushedMem.memberUID == allMember.memberUID){
				  						pushedMem.approved = true
				  						break;
				  					}
				  				}
		  					}
		  				}

		  				
		  			})

		  		}
		  	})

	  	  	this.afs.doc('organization/' + this.currentOrg).valueChanges().subscribe( (data:any) => {

		  		

		  		let questions = data.organizationMemberQuestion
		  		this.profileQuestion = questions
		  		this.tableColumns = []
		  		
		  		if(questions) {
		  			for(let question of questions){
						let cleanseField = question.field
						cleanseField = 'member'+cleanseField
						// cleanseField = cleanseField.split(' ').join('')

						if(question.type == 'auth-phone'){
							cleanseField = 'memberPhone'
						}

						this.tableColumns.push({
								field:cleanseField,
								header:question.field
							})
					}
		  		}
				

				this.tableColumns.push({
					field:'approved',
					header:'Status'
				})

				this.selectedColums = this.tableColumns

		  	})


  		}




  	})


  }


	editUser(user){
		this.dialog.open(MemberDialogComponent, {
			width: '250px',
			data: {
			  operation:'editUser',
			  user:user,
			  question:this.profileQuestion,
			  org:this.currentOrg
			}
		})
	}

	editApproval(user,approval){

		if(approval){

			let groupCollect = this.afs.collection('permission', ref => ref.where('groupPermission','array-contains', this.currentOrg + '.web.viewWeb'))
			let group = groupCollect.valueChanges({idField:"groupId"})
			let groupSub = group.subscribe( (data:any) => {

				let groupId = data[0].groupId;
				let newGroupMember = data[0].groupMember;
				newGroupMember.push(user.memberUID);

				groupSub.unsubscribe();

				this.afs.doc('permission/' + groupId).update({
					groupMember:newGroupMember
				})


			})

		}

		if(!approval){

			let groupCollect = this.afs.collection('permission', ref => ref.where('groupPermission','array-contains', this.currentOrg + '.web.viewWeb'))
			let group = groupCollect.valueChanges({idField:"groupId"})
			let groupSub = group.subscribe( (data:any) => {

				let groupId = data[0].groupId;
				let newGroupMember = data[0].groupMember;
				newGroupMember.splice(newGroupMember.indexOf(user.memberUID),1);

				groupSub.unsubscribe();

				this.afs.doc('permission/' + groupId).update({
					groupMember:newGroupMember
				})


			})

		}

		this.fetchMember()

	}

  ngOnInit(): void {
  }

}
