<div style="display: flex;">
	
	<div style="width: 300px;">

		<mat-list role="list">
		  <mat-list-item role="listitem" *ngFor="let board of postBoards | async" (click)="viewBoard(board)" >{{board.boardName}}</mat-list-item>
		</mat-list>

	</div>

	<div style="width: 100%;">
		
		<div *ngIf="currentBoard">

			<div style="display: flex;justify-content: space-between;align-items: center;padding: 1rem;">
				<div>
					<p style="margin: 0;">current board</p>
					<h1 style="margin: 0;">{{currentBoard.boardName}}</h1>
				</div>

				<div style="height: 100%;">
					<button *ngIf="checkCreatePost" mat-button (click)="newPost()">New Post</button>
				</div>
			</div>

			
			<div style="max-width: 600px">
				
				<mat-list>
				  <mat-list-item *ngFor="let post of posts | async" (click)="editPost(post)" class="hoverListItem">
				    <img matListAvatar src="{{post.postImageURL}}" alt="...">
				    <p matLine>{{post.postName}}</p>
				    <mat-slide-toggle *ngIf="togglePostStatus" [(ngModel)]="post.postStatus" (change)="changePostStatus(post.postId,post.postStatus)"></mat-slide-toggle>
				  </mat-list-item>
				</mat-list>
				
			</div>

		</div>

	</div>


</div>





