import { Component, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeliveryDialogComponent } from '../delivery-dialog/delivery-dialog.component';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore'; 
import { Observable } from 'rxjs';
import { ViewServiceService } from '../view-service.service';
import { PermissionServiceService } from '../permission-service.service';
import { Route, Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-delivery-list',
  templateUrl: './delivery-list.component.html',
  styleUrls: ['./delivery-list.component.scss']
})
export class DeliveryListComponent implements OnInit {

	riderCollection : AngularFirestoreCollection<any>;
	riderList : Observable<Rider>;
	riderArr : any;
  orgId : any;

  offDuty : boolean = false;
  inTransit : boolean = false;
  idle : boolean = false;
  logIn : boolean = false;
  logOut : boolean = false;

  checkCreateRider : boolean = false;
  checkEditRider : boolean = false;
  checkAuthenticateQR : boolean = false;
  checkViewRider : boolean = false;

  constructor(public dialog:MatDialog, private afs:AngularFirestore, private vs:ViewServiceService, private ps:PermissionServiceService, private route:ActivatedRoute) {

  	this.vs.currentOrgObs.subscribe( org => {
  		if(org) {
        this.orgId = org;
  			this.afs.collection<Rider>('organization/'+org+'/rider').valueChanges({idField:'riderId'}).subscribe( (data:any) => {
            this.afs.doc('organization/'+org).valueChanges().subscribe( (question:any) => {
              this.riderArr = [];
              for(let rider of data) {
                
                let ridArr = []; 
                Object.keys(rider).forEach( key => { 
                  for(let ques of question.organizationRiderQuestion) {
                    if(ques.field.toLowerCase().split(' ').join('') == key.replace('rider','').toLowerCase().split(' ').join('')){
                      let newQuestion = [];
                      newQuestion['response'] = rider[key];
                      newQuestion['field'] = key.replace('rider','');
                      ridArr.push(newQuestion);
                      break;
                    }
                  }
                })

                let id = [];
                id['field'] = "riderId";
                id['riderId'] = rider.riderId;
                ridArr.push(id);

                let status = [];
                status['field'] = "riderStatus";
                status['riderStatus'] = rider.riderStatus;
                ridArr.push(status);

                let log = [];
                log['field'] = "riderLoggedIn";
                log['riderLoggedIn'] = rider.riderLoggedIn;
                ridArr.push(log);

                this.riderArr.push(ridArr);
                
              }
            })
  			})

  		}

        this.route.queryParams.subscribe(params => {

          let outletId = params['outlet'];
          if(outletId && this.ps.allowedPermission){
            this.checkCreateRider = this.ps.checkPermission(this.ps.allowedOrganization[0]+'.delivery.createRider');
            this.checkEditRider = this.ps.checkPermission(this.ps.allowedOrganization[0]+'.delivery.editRider');
            this.checkAuthenticateQR = this.ps.checkPermission(this.ps.allowedOrganization[0]+'.delivery.authenticateQR');
            this.checkViewRider = this.ps.checkPermission(this.ps.allowedOrganization[0]+'.delivery.viewRider');

          }
        })

  	})



  }

  ngOnInit(): void {
  }

  showQR(rider : any) {
    
    let riderDocId = "";
    let riderName = "";
    for(var i=0; i<rider.length; i++) {
      if(rider[i].field == 'riderId') {
        riderDocId = rider[i].riderId; 
      }
      if(rider[i].field == 'Name') {
        riderName = rider[i].response;
      }
    }

    let token = new Date(Date.now());
    token.setMinutes(token.getMinutes()+1);

    this.afs.doc('organization/'+this.orgId+'/rider/'+riderDocId).update({
        riderQRATE : token,
        riderLoggedIn : false,
    })

    this.dialog.open(DeliveryDialogComponent, {
      width : '300px',
      data : {
          organization: this.orgId,
          riderDocId : riderDocId,
          riderName : riderName,
          method : 'QR',
      }
    })
  
  }

  createRider() {
  	this.dialog.open(DeliveryDialogComponent, {
			width : '250px',
		})
  }

  updateRider(rider : any) {
  	this.dialog.open(DeliveryDialogComponent, {
  		width : '250px',
  		data : {
  			rider : rider,
  			method : 'update',
  		}
  	})

  }

  deleteRider(rider : any) {
  	this.dialog.open(DeliveryDialogComponent, {
  		width : '250px',
  		data : {
  			rider : rider,
  			method : 'delete',
  		}
  	})

  }

}


export interface Rider{
	riderName: string;
	riderPhone: string;
	riderCarPlate: string;
	riderModel: string;

}



