import { Component, OnInit } from '@angular/core';
import { OrderServiceService } from '../order-service.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@Component({
  selector: 'app-package-config-component',
  templateUrl: './package-config-component.component.html',
  styleUrls: ['./package-config-component.component.scss']
})
export class PackageConfigComponentComponent implements OnInit {

	

  constructor(public os:OrderServiceService) { }

  ngOnInit(): void {
  	
  }

}
