<div style="display: flex;flex-direction: column;width: 100%;padding: 10px;">
	<div *ngIf="checkCreateRider">
		<button mat-raised-button (click)="createRider()">Create Rider</button>
	</div>

	<div *ngIf="checkViewRider" style="padding-top: 10px; padding-bottom: 10px;">
		<mat-card>

			<mat-card-content>
				<mat-checkbox [(ngModel)]="offDuty" style="margin:0 15px;">Off Duty</mat-checkbox>
				<mat-checkbox [(ngModel)]="inTransit" style="margin:0 15px;">In Transit</mat-checkbox>
				<mat-checkbox [(ngModel)]="idle" style="margin:0 15px;">Idle</mat-checkbox>
				<mat-checkbox [(ngModel)]="logIn" style="margin:0 15px;">Logged In</mat-checkbox>
				<mat-checkbox [(ngModel)]="logOut" style="margin:0 15px;">Logged Out</mat-checkbox>
			</mat-card-content>
			
		</mat-card>
	</div>

<div style="display: flex;flex-wrap: wrap;">
	<div style="max-width: 100%; display: flex;flex-wrap: wrap;">
		<div *ngFor="let rider of riderArr | riderStatus:[offDuty, inTransit, idle, logIn, logOut]" style="padding: 0.25rem;">
			<mat-card class="example-card" style="width: 200px;height: 200px;text-align: center;">
			  
			  
				<mat-card-content style="text-align: left; justify-content: space-between; display: flex; flex-direction: column;">
					<div *ngFor="let res of rider">
						<h3 style="text-align: center; margin-bottom: 5px;" *ngIf="res.field == 'Name'"><strong>{{res.response}}</strong></h3>
					</div>

					<div *ngFor="let res of rider">
						<p *ngIf="res.field != 'Name' && res.field != 'riderId' && res.field != 'riderStatus' 
						&& res.field != 'riderLoggedIn'">{{res.field}}: {{res.response}}</p>
					</div>

				</mat-card-content>
				<mat-card-footer style="text-align: left; padding-left: 20px;">
			    
				  	<div>
				  		<mat-icon *ngIf="checkAuthenticateQR" (click)="showQR(rider)" aria-hidden="false">developer_board</mat-icon>&nbsp;
				  		<mat-icon *ngIf="checkEditRider" (click)="updateRider(rider)" aria-hidden="false">edit</mat-icon>&nbsp;
				  		<mat-icon *ngIf="checkEditRider" (click)="deleteRider(rider)" aria-hidden="false">delete</mat-icon>
				  	</div>
				</mat-card-footer>
			  	
			  	
			</mat-card>
			
		</div>
	</div>

	
	

	
</div>

</div>

