import { Component, OnInit, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ItemDialogComponent } from '../item-dialog/item-dialog.component';
import { MenuServiceService } from '../menu-service.service';

@Component({
  selector: 'app-item-component',
  templateUrl: './item-component.component.html',
  styleUrls: ['./item-component.component.scss']
})

export class ItemComponentComponent implements OnInit {

	titleName : string = "";

	constructor( public dialog:MatDialog, public ms : MenuServiceService) {	}

	ngOnInit(){ }
	
	createItem() {
		this.dialog.open(ItemDialogComponent, {
			width : '300px',
		})
	}

	itemDetailShow(id : string) {
		this.dialog.open(ItemDialogComponent, {
			width : '300px',
			data : {
				itemId : id,
			}
		})
	}

}
