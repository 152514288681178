

<!-- <p>Current Outlet:</p>
<h1>{{vs.viewOutlet.outletName}}</h1> -->

<!-- <div style="display: flex;">
	
	<div style="width: 200px;">
		
		<ul>
			<button *ngFor="let outlet of ps.allowedOutlet" (click)="vs.switchOutlet(outlet)">{{outlet.outletName}}</button>
		</ul>


	</div>
	<div style="width: 100%;">
		
		<p><a (click)="vs.outletView('dashboard')">Dashboard</a></p>
		<p><a (click)="vs.outletView('item')">Item</a></p>
		<p><a (click)="vs.outletView('category')">Category</a></p>
		<p><a (click)="vs.outletView('post')">Post</a></p>

	</div>



</div> -->

<div style="height: 100%;">
	<app-organization-component></app-organization-component>
	<mat-tab-group style="height: 100%;">
  <mat-tab *ngIf="orderTab" label="Order">
  	<app-order-component></app-order-component>
  </mat-tab>
	<mat-tab *ngIf="memberTab" label="Member">
		<app-member-component></app-member-component>
	</mat-tab>
	<mat-tab *ngIf="deliveryTab" label="Delivery">
		<app-delivery-component></app-delivery-component>
	</mat-tab>
  <mat-tab *ngIf="menuTab" label="Menu" > 
  	<app-menu-component></app-menu-component>
  </mat-tab>
  <mat-tab *ngIf="postTab" label="Post">
  	<app-post-component></app-post-component>
  </mat-tab>
	<mat-tab *ngIf="profileTab" label="Profile">
		<app-order-config-component></app-order-config-component>
	</mat-tab>

	<mat-tab *ngIf="packageTab" label="Package Config">
		<app-package-config-component></app-package-config-component>
	</mat-tab>
</mat-tab-group>

</div>


<!-- 
<router-outlet></router-outlet> -->