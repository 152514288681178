<div style="display: flex;height: 100%;">
	
	<div style="max-width: 200px;height: 100%;background: white;padding: 1rem 0;">
		<mat-list role="list" style="width: 100%">
		  <mat-list-item role="listitem" *ngFor="let outlet of ps.allowedOutlet" (click)="vs.switchOutlet(outlet)">{{outlet.outletName}}<span><mat-slide-toggle *ngIf="ms.checkOutletToggle" [(ngModel)]="outlet.outletStatus" (change)="toggleOutletStatus(outlet.outletId,$event.checked)" [disabled]="os.reachLimitToggle"></mat-slide-toggle></span></mat-list-item>

		</mat-list>
	</div>

	<div style="width: 100%;padding: 1rem;">
		<app-category-component></app-category-component>		
	</div>


</div>


