<div *ngIf="data.operation == 'newPost'">
	
	<h1>New Post</h1>


	<div style="display: flex;">
		
		<div style="width: 60%;">
			<mat-form-field class="example-full-width">
			  <mat-label>Post Title</mat-label>
			  <input matInput placeholder="" value="" [(ngModel)]="postName">
			</mat-form-field>


			<mat-form-field class="example-full-width">
				<mat-label>Post Type</mat-label>
				<mat-select [(value)]="postType">
					<mat-option value="promo">Promo</mat-option>
					<mat-option value="info">Info</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field class="example-full-width">
				<mat-label>Post Text</mat-label>
				<textarea matInput [(ngModel)]="postText"></textarea>
			</mat-form-field>

			<div *ngIf="postType == 'promo'">
				
				<mat-form-field class="example-full-width">
				  <mat-label>Promo Code</mat-label>
				  <input matInput placeholder="" type="string" value="" [(ngModel)]="promoCode">
				</mat-form-field>

			</div>


			<br>

			<mat-slide-toggle [(ngModel)]="postStatus" (change)="changeActivationMsg()">{{statusMsg}}</mat-slide-toggle>	
		</div>

		<div style="width: 40%;display: flex;justify-content: center;align-items: center;flex-direction: column;">

			<div style="margin: 1rem;width:150px;height: 150px;background: rgb(237, 237, 237);display: flex;justify-content: center;align-items: center;">
				<img *ngIf="srcResult" src="{{srcResult}}" style="object-fit: cover;width: 100%;height: 100%;">
			</div>
					
			<button type="button" mat-raised-button (click)="fileInput.click()">Choose File</button>
			<input hidden (change)="onFileSelected()" #fileInput type="file" id="file">


		</div>


	</div>




	<button type="button" mat-button (click)="addNewPost()">Add</button>





</div>

<div *ngIf="data.operation == 'editPost'">
	
	<div style="display: flex;justify-content: space-between;width: 100%;">
		<h1>Configure Post</h1>
		<div style="padding-top: 1rem;">
			<button mat-raised-button (click)="updatePost()" style="margin-right: 8px;">Update</button>
			<button mat-raised-button color="warn" (click)="discardChanges()">Disard Changes</button>
		</div>
	</div>

	<div style="display: flex;">
		
		<div style="width: 300px;display: flex;justify-content: center;align-items: center;flex-direction: column;">

			<mat-card *ngIf="!newPostImage" (click)="fileInput.click()" class="hoverItem">
				<div style="width: 250px;height: 250px;">
					<img src="{{this.data.post.postImageURL}}" style="object-fit: cover;width: 100%;height: 100%">
				</div>
			</mat-card>

			<mat-card *ngIf="newPostImage" class="hoverItem">
				<div style="width: 250px;height: 250px;">
					<img src="{{srcResult}}" style="object-fit: cover;width: 100%;height: 100%">
				</div>
			</mat-card>

			<mat-card style="width:250px;margin: 0.5rem 0;" (click)="getNew('postName')" *ngIf="!newPostName" class="hoverItem">
				<h6 style="margin: 0;padding: 0;">Post Title</h6>
				<p style="margin: 0;padding: 0;">{{this.data.post.postName}}</p>
			</mat-card>

			<mat-card style="width:250px;margin: 0.5rem 0;" (click)="getNew('postName')" *ngIf="newPostName" class="hoverItem">
				<h6 style="margin: 0;padding: 0;">Post Title</h6>
				<p style="margin: 0;padding: 0;color: green;">{{newPostName}}</p>
			</mat-card>

			<mat-card style="width:250px;margin: 0;" *ngIf="!newPostText" (click)="getNew('postText')" class="hoverItem">
				<h6 style="margin: 0;padding: 0;">Post Text</h6>
				<p style="margin: 0;padding: 0;">{{this.data.post.postText}}</p>
			</mat-card>

			<mat-card style="width:250px;margin: 0;" *ngIf="newPostText" (click)="getNew('postText')" class="hoverItem">
				<h6 style="margin: 0;padding: 0;">Post Text</h6>
				<p style="margin: 0;padding: 0;color: green;">{{newPostName}}</p>
			</mat-card>

			<div *ngIf="this.data.post.postType == 'promo'">
				<mat-card style="width:250px;margin: 0.5rem 0;" *ngIf="!newPromoCode" (click)="getNew('promoCode')" class="hoverItem">
					<h6 style="margin: 0;padding: 0;">Promo Code</h6>
					<p style="margin: 0;padding: 0;">{{this.data.post.promoCode}}</p>
				</mat-card>

				<mat-card style="width:250px;margin: 0.5rem 0;" *ngIf="newPromoCode" (click)="getNew('promoCode')" class="hoverItem">
					<h6 style="margin: 0;padding: 0;">Promo Code</h6>
					<p style="margin: 0;padding: 0;color: green;">{{newPromoCode}}</p>
				</mat-card>
			</div>

		
			
		</div>

		<div style="width: 600px;height: 50vh; display: flex;">
			<div style="width: 50%;height: 100%;overflow-y: scroll;padding: 15px;">
				<h4 style="text-align: center;">All Items</h4>
				<mat-form-field style="width: 100%;">
					<mat-select [(ngModel)]="postItemSelect" (ngModelChange)="chooseOutlet()">
				    	<mat-option *ngFor="let outlet of ps.allowedOutlet" [value]="outlet.outletId">
				      		{{outlet.outletName}}
				    	</mat-option>
				  	</mat-select>
			  	</mat-form-field>

				<mat-list>
					<mat-list-item *ngFor="let item of postAllItemList" class="hoverItem" (click)=addItemPost(item)>
						<img matListAvatar src="{{item.itemURL}}" alt="...">
						<p matLine>{{item.itemName}}</p>
					</mat-list-item>
				</mat-list>
			</div>
			<div style="width: 50%;height: 100%;overflow-y: scroll;">
				<h4 style="text-align: center;">Item in Post</h4>

				<mat-list>
					<mat-list-item *ngFor="let item of postItem | async" class="hoverItem" (click)=removeItemPost(item.postItemId)>
						<img matListAvatar src="{{item.itemURL}}" alt="...">
						<p matLine>{{item.itemName}}</p>
					</mat-list-item>
				</mat-list>
			</div>
		</div>
	</div>


	<input hidden (change)="onFileSelected()" #fileInput type="file" id="file">






</div>