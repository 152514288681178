import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { environment } from '../environments/environment';

import { FormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';

import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatSelectModule} from '@angular/material/select';
import {MatTabsModule} from '@angular/material/tabs';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatBadgeModule} from '@angular/material/badge';

import {TableModule} from 'primeng/table';
import {MultiSelectModule} from 'primeng/multiselect';
import {DropdownModule} from 'primeng/dropdown';

import { ItemComponentComponent } from './item-component/item-component.component';
import { ItemDialogComponent } from './item-dialog/item-dialog.component';
import { LoginComponentComponent } from './login-component/login-component.component';
import { PanelComponentComponent } from './panel-component/panel-component.component';
import { DashboardComponentComponent } from './dashboard-component/dashboard-component.component';
import { CategoryComponentComponent } from './category-component/category-component.component';
import { CategoryDialogComponent } from './category-dialog/category-dialog.component';
import { OrderComponentComponent } from './order-component/order-component.component';
import { OrderConfigComponentComponent } from './order-config-component/order-config-component.component';
import { OrderDialogComponent } from './order-dialog/order-dialog.component';
import { CheckoutComponentComponent } from './checkout-component/checkout-component.component';
import { OrderFilterPipe } from './order-filter.pipe';
import { CategoryFilterPipe } from './category-filter.pipe';
import { OrganizationComponentComponent } from './organization-component/organization-component.component';
import { OrderTrayComponent } from './order-tray/order-tray.component';
import { UncleanseFieldPipe } from './uncleanse-field.pipe';
import { CompletePipePipe } from './complete-pipe.pipe';
import { MenuComponentComponent } from './menu-component/menu-component.component';
import { PermissionPipePipe } from './permission-pipe.pipe';
import { PostComponentComponent } from './post-component/post-component.component';
import { PostDialogComponent } from './post-dialog/post-dialog.component';
import { StatusPipePipe } from './status-pipe.pipe';
import { CategoryPermissionPipe } from './category-permission.pipe';
import { MemberComponentComponent } from './member-component/member-component.component';
import { MemberConfigComponent } from './member-config/member-config.component';
import { MemberListComponent } from './member-list/member-list.component';
import { MemberDialogComponent } from './member-dialog/member-dialog.component';

import { MAT_DATE_LOCALE } from '@angular/material/core';
import { FilterDatePipe } from './filter-date.pipe';
import { DeliveryListComponent } from './delivery-list/delivery-list.component';
import { DeliveryComponentComponent } from './delivery-component/delivery-component.component';
import { DeliveryConfigComponent } from './delivery-config/delivery-config.component';
import { DeliveryDialogComponent } from './delivery-dialog/delivery-dialog.component';
import { DeliveryCheckinComponent } from './delivery-checkin/delivery-checkin.component';

import { HttpClientModule } from '@angular/common/http';
import { QRCodeModule } from 'angularx-qrcode';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { DeliveryMapComponent } from './delivery-map/delivery-map.component';

import { AgmCoreModule } from '@agm/core';
import { RiderStatusPipe } from './rider-status.pipe';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { OrderIncompleteDialogComponent } from './order-incomplete-dialog/order-incomplete-dialog.component';
import { RiderIdToNamePipe } from './rider-id-to-name.pipe';
import { PrintDialogComponent } from './print-dialog/print-dialog.component';
import { PackageConfigComponentComponent } from './package-config-component/package-config-component.component';

import { MatProgressBarModule } from '@angular/material/progress-bar';
import { OrderStatusPipePipe } from './order-status-pipe.pipe';

@NgModule({
  declarations: [
    AppComponent,
    ItemComponentComponent,
    ItemDialogComponent,
    LoginComponentComponent,
    PanelComponentComponent,
    DashboardComponentComponent,
    CategoryComponentComponent,
    CategoryDialogComponent,
    OrderComponentComponent,
    OrderConfigComponentComponent,
    OrderDialogComponent,
    CheckoutComponentComponent,
    OrderFilterPipe,
    CategoryFilterPipe,
    OrganizationComponentComponent,
    OrderTrayComponent,
    UncleanseFieldPipe,
    CompletePipePipe,
    MenuComponentComponent,
    PermissionPipePipe,
    PostComponentComponent,
    PostDialogComponent,
    StatusPipePipe,
    CategoryPermissionPipe,
    MemberComponentComponent,
    MemberConfigComponent,
    MemberListComponent,
    MemberDialogComponent,
    FilterDatePipe,
    DeliveryListComponent,
    DeliveryComponentComponent,
    DeliveryConfigComponent,
    DeliveryDialogComponent,
    DeliveryCheckinComponent,
    DeliveryMapComponent,
    RiderStatusPipe,
    OrderIncompleteDialogComponent,
    RiderIdToNamePipe,
    PrintDialogComponent,
    PackageConfigComponentComponent,
    OrderStatusPipePipe
  ],
  entryComponents:[ItemDialogComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireMessagingModule,
    FormsModule,
    MatButtonModule,
    MatDialogModule,
    MatInputModule,
    MatListModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatTabsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatBadgeModule,
    DragDropModule,
    TableModule,
    MultiSelectModule,
    DropdownModule,
    MatIconModule,
    MatCardModule,
    MatToolbarModule,
    MatButtonToggleModule,
    MatExpansionModule,
    HttpClientModule,
    QRCodeModule,
    Ng2ImgMaxModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCW5t0k8NLsJ8_tv5aJn20FRmuoiBVKBok'
    }),
    NgxMaterialTimepickerModule,
    MatProgressBarModule
  ],
  providers: [{provide: MAT_DATE_LOCALE, useValue: 'en-GB'},],
  bootstrap: [AppComponent]
})
export class AppModule { }
