<div style="display: flex;width: 100%;">

	<div style="width: 300px;">
		<mat-list role="list">
		  <mat-list-item role="listitem" *ngFor ="let tray of os.orderTrays | async | permissionPipe:'viewTray'" (click)="viewTray(tray)">
		  	<span matBadge="{{os.orderTrayBadgeArr[tray.trayId]}}" matBadgeOverlap="false">{{tray.trayName}}</span>
		  </mat-list-item>
		</mat-list>
	</div>

	<div style="width: 100%;">

		<div style="display: flex;justify-content: space-between;align-items: center;padding: 1rem;">
			<div style="display: flex;">
				<div>
					<p style="margin: 0;">current tray</p>
					<h1 style="margin: 0;">{{os.currentTray.trayName}}</h1>
				</div>
				<div style="padding-left: 5rem;">
					<p style="padding: 0;margin: 0;">View Date</p>
					<mat-form-field>
					  <mat-label>From</mat-label>
					  <input matInput [matDatepicker]="picker1" [(ngModel)]="os.lowerDate" (dateInput)="filterDate('input', $event)" (dateChange)="filterDate('change', $event)">
					  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
					  <mat-datepicker #picker1></mat-datepicker>
					</mat-form-field>
					<mat-form-field>
					  <mat-label>To</mat-label>
					  <input matInput [matDatepicker]="picker2" [(ngModel)]="os.upperDate" (dateInput)="filterDate('input', $event)" (dateChange)="filterDate('change', $event)">
					  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
					  <mat-datepicker #picker2></mat-datepicker>
					</mat-form-field>
				</div>
			</div>

			<div style="height: 100%;">
				<mat-button-toggle-group>
				  <mat-button-toggle value="bold" (click)="toggleDisplay('grid')">
				  	<mat-icon aria-hidden="false" aria-label="Example home icon">view_module</mat-icon>
				  	<span style="margin: 0;padding: 0;">Grid</span>
				  </mat-button-toggle>
				  <mat-button-toggle value="italic" (click)="toggleDisplay('list')">
				  	<mat-icon aria-hidden="false" aria-label="Example home icon">view_list</mat-icon>
				  	<span style="margin: 0;padding: 0;">List</span>
				  </mat-button-toggle>
				</mat-button-toggle-group>
			</div>
		</div>

<!-- 		<button (click)="toggleDisplay('grid')"></button>
		<button (click)="toggleDisplay('list')">list</button> -->




		<div *ngIf="view == 'grid'">

			<div style="background: rgb(240, 240, 240);display: flex;flex-direction: column;padding: 0.5rem;">

				<h3 style="padding: 0 15px;margin: 0;">Filters</h3>

				<mat-form-field class="example-full-width" style="padding: 0 15px;">
					<input matInput placeholder="search names, items or order profile..." [(ngModel)]="gridSearch">
				</mat-form-field>

				<div>
					<mat-checkbox [(ngModel)]="os.viewComplete" style="margin:0 15px;">View Complete</mat-checkbox>
					<mat-checkbox [(ngModel)]="os.viewIncomplete" style="margin:0 15px;">View Incomplete</mat-checkbox>
					<mat-checkbox [(ngModel)]="os.viewCancelled" style="margin:0 15px;">View Cancelled</mat-checkbox>
				</div>
				
			</div>


			<div style="display: flex;padding: 1rem 0; flex-wrap: wrap;">
				<div *ngFor="let order of os.currentTrayOrder | orderFilter:gridSearch | completePipe:[os.viewComplete,os.viewIncomplete,os.viewCancelled] " style="padding: 1rem;">

					<mat-card style="width: 300px;">

						<div [ngSwitch]="order.trayProfile">

							<div *ngSwitchCase="'Self Pickup'" style="width: 300px;padding: 5px;margin:0 -1rem 1rem -1rem;display: flex;justify-content: center;align-items: center; background: #00a357;">
								<p style="margin: 0;padding: 0;color: white;">{{order.trayProfile}}</p>
							</div>

							<div *ngSwitchCase="'Delivery'" style="width: 300px;padding: 5px;margin:0 -1rem 1rem -1rem;display: flex;justify-content: center;align-items: center; background: #ffb005;">
								<p style="margin: 0;padding: 0;color: white;">{{order.trayProfile}}</p>
							</div>

							<div *ngSwitchCase="'Dine In'" style="width: 300px;padding: 5px;margin:0 -1rem 1rem -1rem;display: flex;justify-content: center;align-items: center; background: #0987ed;">
								<p style="margin: 0;padding: 0;color: white;">{{order.trayProfile}}</p>
							</div>

							<div *ngSwitchDefault style="width: 300px;padding: 5px;margin:0 -1rem 1rem -1rem;display: flex;justify-content: center;align-items: center; background: #dedede;">
								<p style="margin: 0;padding: 0;color: white;">{{order.trayProfile}}</p>
							</div>

						</div>

						

						<div *ngFor="let info of order | keyvalue">

							<div [ngSwitch]="info.key">
								
								<div *ngSwitchCase="'trayItem'">
									
								</div>

								<p *ngSwitchCase="'trayTimestamp'">{{info.key | uncleanseField:['trayOrder','tray']}} : {{info.value | date:'d/M/yyyy E hh:mma'}}</p>

								<p *ngSwitchCase="'trayOrderId'"></p>

								<p *ngSwitchCase="'trayStatus'">{{info.value | statusPipe }}</p>

								<div *ngSwitchCase="'trayOrderAuthenticatedUser'">

								</div>

								<div *ngSwitchCase="'trayOrderDeliveryRider'">
									
								</div>

								<div *ngSwitchCase="'trayProfile'">
									
								</div>

								<p *ngSwitchCase="'orderStatus'">OrderStatus : {{info.value | orderStatusPipe }}</p>


								<div *ngSwitchDefault>
									
									<div [ngSwitch]="info.value.type">
										
										<button mat-raised-button *ngSwitchCase ="'whatsapp-send'" (click)="os.sendWhatsapp(order,info.value.receipientField,info.value.field,riderArr)">{{info.value.field}}</button>
										<p *ngSwitchCase ="'location'">{{info.key | uncleanseField:['trayOrder','tray']}} : {{info.value.response.formatted_address}}</p>
										<p *ngSwitchDefault>{{info.key | uncleanseField:['trayOrder','tray']}} : {{info.value}}</p>


									</div>


								</div>


							</div>

						</div>

						<div *ngIf="order.trayItem">
							<mat-expansion-panel>
							    <mat-expansion-panel-header>
							      <mat-panel-title>
							        Items
							      </mat-panel-title>
							    </mat-expansion-panel-header>
								<ul *ngFor="let item of order.trayItem">
									<li>{{item.itemName}}</li>
								</ul>

							  </mat-expansion-panel>
						</div>

						<div *ngIf="order.trayOrderDeliveryRider">
							<mat-expansion-panel #riderPanel>
								<mat-expansion-panel-header>
									<mat-panel-title>
							        	Delivery Rider
							     	</mat-panel-title>
							     	<mat-panel-description>
								      {{riderArr | riderIdToName:order.trayOrderDeliveryRider}}
								    </mat-panel-description>
							 	</mat-expansion-panel-header>
								<mat-select [(ngModel)]="order.trayOrderDeliveryRider">
									<mat-option *ngFor="let rider of riderArr" [value]="rider.riderDocId">
								      {{rider.riderName}}
								    </mat-option>
								</mat-select>
								<button mat-button (click)="togglePanel(riderPanel,order,order.trayOrderDeliveryRider)">OK</button>
								
							</mat-expansion-panel>
							
						</div>





						<div *ngIf="order.trayOrderAuthenticatedUser">
							<mat-expansion-panel>
							    <mat-expansion-panel-header>
							      <mat-panel-title>
							        Authenticated User
							      </mat-panel-title>
  							      <mat-panel-description>
							        {{order.trayOrderAuthenticatedUser.memberName}}
							      </mat-panel-description>
							    </mat-expansion-panel-header>

							    <div *ngFor="let userInfo of order.trayOrderAuthenticatedUser | keyvalue">
							    	<p *ngIf="userInfo.key != 'memberUID'">{{userInfo.key | uncleanseField:['member']}}: {{userInfo.value}}</p>
							    </div>

							  </mat-expansion-panel>
						</div>

						<div style="padding-top: 15px;">
							<button mat-button *ngIf="completeOrder" (click)="os.editOrderStatus(order,'completed')">Complete</button>
							<button mat-button *ngIf="editOrderDetails" (click)="editOrder(order)">Edit</button>
							<button mat-button (click)="printOrder(order)"><mat-icon>print</mat-icon></button>
						</div>

					</mat-card>
					
				

				</div>
				
			</div>


			

			
		</div>

		<div *ngIf="view == 'list'" style="height: 100%;">
			
		<p-table #dt [value]="os.sortedOrder[os.currentTray.trayName]" [columns]="os.selectedColumns">
			<ng-template pTemplate="caption">
		        <div style="text-align:left">
		             <p-multiSelect
				      [options]="os.orderTableCol"
				      [(ngModel)]="os.selectedColumns"
				      [style]="{width: '100%'}"
				      optionLabel="header"
				      defaultLabel="Choose Columns"
				      selectedItemsLabel="{0} columns selected">
				    </p-multiSelect>
		        </div>
		    </ng-template>
		    <ng-template pTemplate="header" let-columns="os.selectedColumns">
		    <tr>
		      <th *ngFor="let col of os.selectedColumns" [pSortableColumn]="col.field">
		      	{{col.header}}
		      	<p-sortIcon [field]="col.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>

		      	<p-dropdown *ngIf="col.field == 'trayProfile'" [options]="os.orderProfileOption" [style]="{'width':'100%'}" (onChange)="dt.filter($event.value, col.field, 'equals')"></p-dropdown>

		      	<p-dropdown *ngIf="col.field == 'trayStatus'" [options]="os.orderStatusOption" [style]="{'width':'100%'}" (onChange)="dt.filter($event.value, col.field, 'equals')"></p-dropdown>

		      </th>
		      
		    </tr>
		  </ng-template>
		  <ng-template pTemplate="body" let-item let-columns="columns">
		    <tr>
		      <td *ngFor="let col of columns" [ngSwitch]="col.field" (click)="editOrder(item)">
						<div *ngSwitchCase="'trayTimestamp'">
							{{item[col.field] | date:'d/M/yyyy E hh:mma'}}
						</div>
						<div *ngSwitchCase="'trayItem'">
							
							<div *ngFor="let orderItem of item[col.field]">
								<p>{{orderItem.itemName}}</p>
							</div>


						</div>
						<div *ngSwitchCase="'trayStatus'">
							{{item[col.field]	| statusPipe }}
						</div>
						<div *ngSwitchDefault>
							{{item[col.field]}}
						</div>
					</td>
		    </tr>
		  </ng-template>
		    <!-- <ng-template pTemplate="header">
		        <tr>
		            <th *ngFor="let col of os.pendingTableCol" [pSortableColumn]="col.field">
		                {{col.header}}
		                <p-sortIcon [field]="col.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
		            </th>
		        </tr>
		    </ng-template>
		    <ng-template pTemplate="body" let-order>
		        <tr>
		            <td *ngFor="let col of os.pendingTableCol">
		                    <div *ngIf="col.field == 'orderTimestamp'">
		                    	{{order[col.field]	| date:'d/M/yyyy E hh:mma'}}
		                    </div>
		                    <div *ngIf="col.field != 'orderTimestamp'">
		                    	{{order[col.field]}}
		                    </div>

		                    
		            </td>
		        </tr>
		    </ng-template> -->
		</p-table>

		</div>


	</div>

</div>