import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderDialogComponent } from '../order-dialog/order-dialog.component';
import { OrderServiceService } from '../order-service.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { PermissionServiceService } from '../permission-service.service';



@Component({
	selector: 'app-order-config-component',
	templateUrl: './order-config-component.component.html',
	styleUrls: ['./order-config-component.component.scss']
})
export class OrderConfigComponentComponent implements OnInit {
	
	profileQuestion = [ ];

	////permission
	createProfile:boolean
	editProfile:boolean

	questionType = [
	{
		type:'short',
		field:'Enter your question'
	},
	{
		type:'long',
		field:'Enter your question'
	},
	{	
		type:'date',
		field:'Enter your question',
		maxDateFuture:''
	},
	{
		type:'time',
		field:'Enter your question',
		bufferMin: '0',		////the ealiest option available from current time
		minTime:'00:00',
		maxTime:'23:59',
		timeOnly:false
	},
	{
		type:'promotion',
		field:'Enter your question'
	},
	{
		type:'dropdown',
		field:'Enter your question',
		option:['option1','option2','option3']
	},
	{
		type:'location',
		field:'Location',
	},
	{
		type:'whatsapp-send',
		field:'WhatsApp Send',
		receipientField:'',
		message:''
	},
	{
		type:'auth-user',
		field:'Authenticated User',
	},
	{
		type:'rider',
		field:'Delivery Rider',
	},
	];

	constructor(private afs:AngularFirestore, public dialog:MatDialog, public os:OrderServiceService, public ps:PermissionServiceService) {

		this.os.currentOrgObs.subscribe( data => {
			if(data){
				this.createProfile = this.ps.checkPermission(data + '.profile.createProfile')
				this.editProfile = this.ps.checkPermission(data + '.profile.editProfile')

				this.afs.doc('organization/' + data).valueChanges().subscribe((data:any) => {
					if(data.organizationRiderQuestion){
						this.ps.organizationRiderQuestion = data.organizationRiderQuestion
					}
				})


			}
		})

	}

	ngOnInit(): void {
		codCheck : this.os.codCheck;
  		offlineCheck : this.os.offlineCheck;
  		stripeCheck : this.os.stripeCheck;
	
	}


	addNewProfile() {
		this.dialog.open(OrderDialogComponent, {
			width: '250px',
			data: {
				operation:'newOrderProfile'
			}
		})
	}

	updateProfileName() {
		this.dialog.open(OrderDialogComponent, {
			width: '250px',
			data: {
				operation:'updateProfileName'
			}
		})
	}


	drop(event: CdkDragDrop<string[]>) {

		if (event.previousContainer === event.container) {
			moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
		} else {
			transferArrayItem(event.previousContainer.data,
				event.container.data,
				event.previousIndex,
				event.currentIndex);
		}

		this.questionType = [
		{
			type:'short',
			field:'Enter your question'
		},
		{
			type:'long',
			field:'Enter your question'
		},
		{
			type:'date',
			field:'Enter your question',
			maxDateFuture:''
		},
		{
			type:'time',
			field:'Enter your question',
			bufferMin: '0',
			minTime:'00:00',
			maxTime:'23:59',
			timeOnly:false
		},
		{
			type:'promotion',
			field:'Enter your question'
		},
		{
			type:'dropdown',
			field:'Enter your question',
			option:['option1','option2','option3']
		},

		{
			type:'location',
			field:'Location',
		},
		{
			type:'whatsapp-send',
			field:'WhatsApp Send',
			receipientField:'',
			message:''
		},
		{
			type:'auth-user',
			field:'Authenticated User',
		},
		{
			type:'rider',
			field:'Delivery Rider',
		},
		];

	}

	editField(index){
		let newField = prompt('Enter the title of this question:');
		if(newField){
			this.os.currentProfile.profileQuestion[index].field = newField;
		}
	}

	removeField(index){
		this.os.currentProfile.profileQuestion.splice(index,1);
	}

	addOption(index){

		let newOption = prompt('Enter new option');

		if(newOption){
			let optionArr = this.os.currentProfile.profileQuestion[index].option;
			optionArr.push(newOption)
		}

	}

	removeOption(i,j){
		let optionArr = this.os.currentProfile.profileQuestion[i].option;
		optionArr.splice(j,1)
		this.os.currentProfile.profileQuestion[i].option = optionArr
	}

	editTime(i,op){

		if(op == "min"){
			this.os.currentProfile.profileQuestion[i].minTime = prompt("Enter the minimum time in 24 hour format. eg: 13:30")
		}

		if(op == "max"){
			this.os.currentProfile.profileQuestion[i].maxTime = prompt("Enter the minimum time in 24 hour format. eg: 13:30.")
		}

		if(op == "buffer"){
			this.os.currentProfile.profileQuestion[i].bufferMin = prompt("Enter the buffer time in minutes. eg: 30 (indicates 30 minutes)")	
		}

	}

	editDate(i){
		this.os.currentProfile.profileQuestion[i].maxDateFuture = prompt("Enter the maximum day ahead available. (Enter 3 if only accept dates that's 3 day away from the current date, not including current date.)")
	}

	detectSummoner(item){
		let currrentLength = item.message.length;
		let theLast2 = item.message.charAt(currrentLength-2) + item.message.charAt(currrentLength-1)

		if(theLast2 == "<<"){
			console.log('Summon!')

			const dialogRef = this.dialog.open(OrderDialogComponent, {
				width: '250px',
				data: {
					operation:'showAvailableVariable',
					variable:'Select Variable'
				}
			})

			dialogRef.afterClosed().subscribe(result => {
				item.message = item.message + result + '>>'
			});

		}

	}



	setPaymentMethod(event:any, method:string) {

		if(event && method == 'cod') {
			this.os.codCheck = true;
		}
		if(event && method == 'offline') {
			this.os.offlineCheck = true;
		}
		if(event && method == 'stripe') {
			this.os.stripeCheck = true;
		}

		if(!event && method == 'cod') {
			this.os.codCheck = false;
		}
		if(!event && method == 'offline') {
			this.os.offlineCheck = false;
		}
		if(!event && method == 'stripe') {
			this.os.stripeCheck = false;
		}

	}

}
