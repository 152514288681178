

<div style="display: flex;justify-content: space-around;align-items: center; width: 100%;">

	<div style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
		<h1>Check In</h1>
		<p>Please scan with the Rider Companion App.</p>

		<qrcode [qrdata]="qrDataCheckIn" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
	</div>

	<div style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
		<h1>Check Out</h1>
		<p>Please scan with the Rider Companion App.</p>

		<qrcode [qrdata]="qrDataCheckOut" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
	</div>
	
</div>