<p>checkout-component works!</p>

<div>
	
	<button *ngFor="let profile of activeProfile | async" (click)="chooseProfile(profile)">{{profile.profileName}}</button>

</div>

<div *ngIf="selectedProfile">
	
	<h1>{{selectedProfile.profileName}}</h1>

	<div *ngFor="let question of selectedProfile.profileQuestion; let i = index">
		
		<div *ngIf="question.type == 'short'">			
			<h3>{{question.field}}</h3>
			<input type="" name="" [(ngModel)]="orderResponse[i].response">
		</div>

		<div *ngIf="question.type == 'long'">			
			<h3>{{question.field}}</h3>
			<textarea [(ngModel)]="orderResponse[i].response"></textarea>
		</div>

		<div *ngIf="question.type == 'dropdown'">			
			<h3>{{question.field}}</h3>
			<select [(ngModel)]="orderResponse[i].response">
				<option *ngFor="let option of question.option" value="{{option}}">{{option}}</option>
			</select>
		</div>


	</div>

	<button (click)="checkout(selectedProfile)">Place Order</button>




</div>
