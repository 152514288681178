import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore'; 
import { AngularFireStorage } from '@angular/fire/storage';
import { MenuServiceService } from '../menu-service.service'
import { Category } from '../menu-service.service';

@Component({
  selector: 'app-category-dialog',
  templateUrl: './category-dialog.component.html',
  styleUrls: ['./category-dialog.component.scss']
})

export class CategoryDialogComponent implements OnInit {
	categoryName : string  =  "";
	categoryStatus : boolean = false;
	update : boolean = false;
	create : boolean = false;
	delete : boolean = false;
	orderTrayList : any[] = [];

	constructor(private storage: AngularFireStorage, private afs:AngularFirestore, public dialog:MatDialog, public ms:MenuServiceService, @Inject(MAT_DIALOG_DATA) public data: any) { }

	ngOnInit() { 
		if(this.data != null) {
			// console.log('data', this.data);
			if(this.data.categoryMethod == "update") {
				this.update = true;
				this.categoryName = this.data.categoryName;
				this.categoryStatus = this.data.categoryStatus;
				this.orderTrayList = this.data.categoryTray;
			}
			else if(this.data.categoryMethod == "delete") {
				this.delete = true;
			}
			
		}
		else {
			this.create = true;
		}
	}

	onChange(id : string, isChecked : boolean) {
		if(isChecked) {
			this.orderTrayList.push(id);
		} else {
			let index = this.orderTrayList.indexOf(id);
			this.orderTrayList.splice(index,1);
		}
	}

	checkTray(id : string) {
		for(var i = 0; i < this.orderTrayList.length; i++) {
			if(id == this.orderTrayList[i]) {
				return true;
			}
		}
		return false;

	}

	createCategory() {
		let newDocId = this.afs.createId();
		let newCategoryIndex = 0;

		if(this.ms.categoryArr.length > 0){
	      newCategoryIndex = this.ms.categoryArr.length
	    }else{
	      newCategoryIndex = 0;
	    }

		this.ms.categorysCollection.add({
			categoryName: this.categoryName,
			categoryStatus: this.categoryStatus,
			categoryIndex: newCategoryIndex,
			categoryTray: this.orderTrayList,

		});
		this.dialog.closeAll();
	}

	updateCategory(id : string) {
		this.afs.doc('outlet/' + this.ms.currentOutlet + '/category/' + id).update({
			categoryName : this.categoryName,
			categoryStatus : this.categoryStatus,
			categoryTray : this.orderTrayList,
		});
		this.dialog.closeAll();

	}

	deleteCategory(id : string) {
		this.afs.doc('outlet/' + this.ms.currentOutlet + '/category/' + id).delete();
		this.dialog.closeAll();
	}

}
