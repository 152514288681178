import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-print-dialog',
  templateUrl: './print-dialog.component.html',
  styleUrls: ['./print-dialog.component.scss']
})
export class PrintDialogComponent implements OnInit {

	orderData:any;

  constructor(public dialogRef: MatDialogRef<PrintDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData, ) {
  	this.orderData = data.order;
   }

  ngOnInit(): void {
  }

}

export interface DialogData{
	order:any
}
