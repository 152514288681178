import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'riderStatus'
})
export class RiderStatusPipe implements PipeTransform {

  transform(rider : any[], status : any[]) {

  	let riderArr = [];

  	if(status[0]) {
  		for(let rid of rider) {
  			for(let r of rid) {
  				if(r.riderStatus == "off-duty") {
  					riderArr.push(rid);
  				}
  			}
  		}
  	}

  	if(status[1]) {
  		for(let rid of rider) {
  			for(let r of rid) {
  				if(r.riderStatus == "in-transit") {
  					riderArr.push(rid);
  				}
  			}
  		}
  	}

  	if(status[2]) {
  		for(let rid of rider) {
  			for(let r of rid) {
  				if(r.riderStatus == "idle") {
  					riderArr.push(rid);
  				}
  			}
  		}
  	}

  	if(status[3]) {
  		for(let rid of rider) {
  			for(let r of rid) {
  				if(r.riderLoggedIn == true) {
  					riderArr.push(rid);
  				}
  			}
  		}
  	}

  	if(status[4]) {
  		for(let rid of rider) {
  			for(let r of rid) {
  				if(r.riderLoggedIn == false) {
  					riderArr.push(rid);
  				}
  			}
  		}
  	}

    return riderArr;
  }

}
