import { Component, OnInit, Inject} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';
import { MenuServiceService } from '../menu-service.service';
import { PermissionServiceService } from '../permission-service.service';
import 'firebase/storage';

@Component({
  selector: 'app-post-dialog',
  templateUrl: './post-dialog.component.html',
  styleUrls: ['./post-dialog.component.scss']
})
export class PostDialogComponent implements OnInit {

	postType:string = 'promo'

	postStatus:boolean = true
	postName:string
  	postText:string
	promoCode:string = ''
	statusMsg:string

	srcResult:any
	postImage:any

	newPostImage:any

	postItemList:Item[] = [];
	postItemCollection:AngularFirestoreCollection<Item>
	postItem:Observable<Item[]>

  newPostName:string
  newPostText:string
  newPromoCode:string

  postAllItemList : any = [];
  postItemSelect : string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, private afs:AngularFirestore,private storage: AngularFireStorage, public ms : MenuServiceService, public ps : PermissionServiceService) {
  	this.changeActivationMsg();
    if(this.data.operation == 'editPost') {
      this.postItemSelect = this.ps.allowedOutlet[0].outletId;
      this.chooseOutlet();

      this.postItemCollection = this.afs.collection<Item>('post/' + this.data.post.postId + '/postItem/')
      this.postItem = this.postItemCollection.valueChanges({idField:'postItemId'})

    }
  }

  ngOnInit(): void { 
  }

  chooseOutlet() {

    this.afs.collection('outlet/' + this.postItemSelect + '/category').valueChanges({idField:'categoryId'}).subscribe( (data:any) => {

      let categoryArr = data;

          this.afs.collection<Item>('outlet/'+this.postItemSelect+'/item').valueChanges().subscribe( data => {

            this.postAllItemList = []

            for(let item of data){

              for(let category of categoryArr){
                if(category.categoryId == item.itemCategory){
                  item.itemTray = category.categoryTray
                }
              }
              this.postAllItemList.push(item)
            }

            
          })

    })




  }

  changeActivationMsg(){

  	if(this.postStatus){
  		this.statusMsg = "Active"
  	}else{
  		this.statusMsg = "Disabled"
  	}

  }

    onFileSelected() {

  	  const inputNode: any = document.querySelector('#file');

	  if (typeof (FileReader) !== 'undefined') {
	    const reader = new FileReader();

	    reader.onload = (e: any) => {
	      this.srcResult = e.target.result;
	      this.postImage = inputNode.files[0]
	    };

	    reader.readAsDataURL(inputNode.files[0]);
	  }
	}




   addNewPost() {

   	let newDocId = this.afs.createId();
   	
    const file = this.postImage;
    const filePath = 'post/' + newDocId;
    const task = this.storage.upload(filePath, file).then( () => {


        const ref = this.storage.ref('post/'+ newDocId);
        let url = ref.getDownloadURL();
       

        url.subscribe( link =>{

	        this.afs.doc('post/' + newDocId).set({
				postName:this.postName,
				promoCode:this.promoCode,
				postStatus:this.postStatus,
				postImageURL:link,
				postText:this.postText,
				postType:this.postType,
				postBoard:this.data.boardId
	        })

        })


    });
  }


  addItemPost(selectedItem){
		this.postItemCollection.add(selectedItem);
	}

	removeItemPost(postItemId){
		this.afs.doc('post/' + this.data.post.postId + '/postItem/' + postItemId).delete();
	}

  updatePost(){

	if(this.srcResult){

		this.storage.ref('post/' + this.data.post.postId).delete()

		const file = this.newPostImage;
		const filePath = 'post/' + this.data.post.postId;
		const task = this.storage.upload(filePath, file).then( () => {


			const ref = this.storage.ref('post/' + this.data.post.postId);
			let url = ref.getDownloadURL();

			url.subscribe( link =>{

				this.afs.doc('post/' + this.data.post.postId).update({
					postImageURL:link
				})
			})


		});

	}

	let postDoc =  this.afs.doc('post/' + this.data.post.postId)

    if(this.newPostName){
      postDoc.update({
        postName:this.newPostName
      })
    }

    if(this.newPostText){
      postDoc.update({
        postText:this.newPostText
      })
    }

    if(this.newPromoCode){
      postDoc.update({
        promoCode:this.newPromoCode
      })
    }

  }

  getNew(field:string){

    if(field == 'postName'){
      this.newPostName = prompt("Please enter new post title")
    }

    if(field == 'postText'){
      this.newPostText = prompt("Please enter new post text")
    }

    if(field == 'promoCode'){
      this.newPromoCode = prompt("Please enter new promo code")
    }


  }

  discardChanges(){
    this.srcResult = null
    this.newPostImage = null
    this.newPostName = null
    this.newPostText = null
  }

}

export interface DialogData{
	operation:string
	boardId:string
	post:any
}

export interface Item {
  postItemId:string
  itemId:string
  itemName:string
  itemPrice:number
  itemDesc:string
  itemCategory:string
  itemURL:string
  itemTray:any
}