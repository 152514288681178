<agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom">
	<agm-marker *ngFor="let marker of allMarkers" [latitude]="marker.position.lat" [longitude]="marker.position.lng" [iconUrl]="{url:'../../assets/rsz_rider.png',labelOrigin:{x:15,y:55}}" [label]="marker.labelOption">
		
		<agm-info-window [disableAutoPan]="false">
			<div>
				<p>{{marker.riderName}}</p>
			</div>
        </agm-info-window>


	</agm-marker>
</agm-map>

<div style="display: flex;">
	
	<div *ngFor="let rider of allRider" style="padding: 0.5rem;">
		<mat-card style="width: 300px;" (click)="centerMarker(rider.riderDocId)">
			
			<div style="display: flex;justify-content: space-between;align-items: center;">
				<h1>{{rider.riderName}}</h1>
				<p>Status: {{rider.riderStatus}}</p>
			</div>
			<div *ngFor="let res of rider.orderResponse">

				<div [ngSwitch]="res.type">					
					<p *ngSwitchCase="'location'">Address: {{res.response.formatted_address}}</p>
					<p *ngSwitchCase="'rider'"></p>
					<p *ngSwitchCase="'whatsapp-send'"></p>
					<p *ngSwitchDefault>{{res.field}}: {{res.response}}</p>
				</div>

				

			</div>
			
			<div *ngFor="let item of rider.orderItem">
				<p>{{item.itemName}}</p>
			</div>

		</mat-card>

	</div>

</div>
