import { Component, OnInit } from '@angular/core';
import { UserServiceService } from '../user-service.service'
import { PermissionServiceService } from '../permission-service.service';
import { Route, Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ViewServiceService } from '../view-service.service'
import { OrderServiceService } from '../order-service.service';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Permission } from '../permission-service.service';

@Component({
  selector: 'app-organization-component',
  templateUrl: './organization-component.component.html',
  styleUrls: ['./organization-component.component.scss']
})
export class OrganizationComponentComponent implements OnInit {

	receivedOrderDisplay : boolean = false;

  constructor(public us:UserServiceService, public vs:ViewServiceService, public ps:PermissionServiceService,private router: Router,private route: ActivatedRoute, public os:OrderServiceService, private afs:AngularFirestore) {

  	this.vs.currentOrgObs.subscribe( org => {

  		if(org){

        	this.receivedOrderDisplay = this.ps.checkPermission(org + '.package.receivedOrderDisplay');
  		}

  	})


  }

  ngOnInit(): void {



  }

}
