import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusPipe'
})
export class StatusPipePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {

  	if(value == 'cancelled'){
  		return 'Cancelled'
  	}
    
    if(value == true){
    	return 'Completed'
    }

    if(value == false){
    	return 'Pending'
    }

    return null;


  }

}
