<div style="display: flex;height: 100%;">
	
	<div style="min-width: 200px;background: white;height: 100%">
		
		<mat-list role="list" style="width: 100%">
			<mat-list-item (click)="toggleSetting(false)">Members</mat-list-item>
		  <mat-list-item (click)="toggleSetting(true)">Settings</mat-list-item>
		</mat-list>



	</div>
	<div style="width: 100%;">
		<app-member-config *ngIf="showSetting"></app-member-config>
		<app-member-list *ngIf="!showSetting"></app-member-list>
	</div>




</div>



