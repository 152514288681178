import { Component, OnInit } from '@angular/core';
import { ViewServiceService } from '../view-service.service';
import { PermissionServiceService } from '../permission-service.service';
import { Route, Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-delivery-component',
  templateUrl: './delivery-component.component.html',
  styleUrls: ['./delivery-component.component.scss']
})
export class DeliveryComponentComponent implements OnInit {

  	showComponent:string

    checkViewMap : boolean = false;
    checkViewRider : boolean = false;
    checkDeliveryConfig : boolean = false;
    checkCheckInQR : boolean = false;

  constructor(private vs:ViewServiceService, private ps:PermissionServiceService, private route:ActivatedRoute) {
  	this.showComponent = 'list';

    this.vs.currentOrgObs.subscribe( org => {
      this.route.queryParams.subscribe(params => {

        let outletId = params['outlet'];

        if(outletId && this.ps.allowedPermission && org){
          this.checkViewMap = this.ps.checkPermission(org+'.delivery.viewMap');

          this.checkViewRider = this.ps.checkPermission(org+'.delivery.viewRider');

          this.checkDeliveryConfig = this.ps.checkPermission(org+'.delivery.editProfile');

          this.checkCheckInQR = this.ps.checkPermission(org+'.delivery.checkInOutQR');
        }
      })
    })
  }

  ngOnInit(): void {
  }

  toggleSetting(component:string){
  	this.showComponent = component;
  }

}
