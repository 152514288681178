import { Pipe, PipeTransform } from '@angular/core';
import { OrderServiceService } from './order-service.service';

@Pipe({
  name: 'completePipe'
})
export class CompletePipePipe implements PipeTransform {

	constructor( public os:OrderServiceService){

	}


  transform(item: any[], arr:any[]): unknown {
  	
  	let filterArr = []
    
  	if(arr[0]){
  		for(let order of item){
  			if(order.trayStatus == true && order.trayStatus != 'cancelled'){
  				filterArr.push(order)
  			}
  		}
  	}

	  if(arr[1]){
  		for(let order of item){
  			if(!order.trayStatus){
  				filterArr.push(order)
  			}
  		}
  	}

    if(arr[2]){
      for(let order of item){
        if(order.trayStatus == 'cancelled'){
          filterArr.push(order)
        }
      }
    }

  		return filterArr;

  }

}
