import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'riderIdToName'
})
export class RiderIdToNamePipe implements PipeTransform {

  transform(riderArr: any[], id: string): any {
    
  	for(let rider of riderArr){
  		if(rider.riderDocId == id){
  			return rider.riderName;
  		}
  	}


  }

}
