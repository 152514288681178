import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-dashboard-component',
  templateUrl: './dashboard-component.component.html',
  styleUrls: ['./dashboard-component.component.scss']
})
export class DashboardComponentComponent implements OnInit {

    constructor(private afs:AngularFirestore) {

  	let orderCol = this.afs.collection('order' , ref => ref.where('tray','array-contains','trayId'))
  	let order = orderCol.valueChanges()

  	order.subscribe( data => {
  		console.log('test', data)
  	})



  }

  ngOnInit(): void {
  }

}
