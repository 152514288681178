<div *ngIf="create">
	<h1 mat-dialog-title>Create Rider</h1>
		<div mat-dialog-content>

			<div *ngFor="let question of riderQuestion; let i = index;">
					
					<div [ngSwitch]="question.type">

						<mat-form-field *ngSwitchCase="'short'">
							<mat-label position="stacked">{{question.field}}</mat-label>
							<input matInput [(ngModel)]="newRiderResponse[i].response">
						</mat-form-field>

						<mat-form-field *ngSwitchCase="'long'">
							<mat-label position="stacked">{{question.field}}</mat-label>
							<textarea matInput [(ngModel)]="newRiderResponse[i].response"></textarea>
						</mat-form-field>

						<mat-form-field *ngSwitchCase="'time'">
							<mat-label position="stacked">{{question.field}}</mat-label>
							<input matInput type="date" displayFormat="h:mm A" pickerFormat="HH:mm" [(ngModel)]="newRiderResponse[i].response" [min]="newRiderResponse[i].minTime" [max]="newRiderResponse[i].maxTime">
						</mat-form-field>

						<mat-form-field *ngSwitchCase="'date'">
							<mat-label position="stacked">{{question.field}}</mat-label>
							<input matInput type="date" [max]="newRiderResponse[i].maxDateFuture" [min]="newRiderResponse[i].minDate" [(ngModel)]="newRiderResponse[i].response">
						</mat-form-field>

						<mat-form-field *ngSwitchCase="'dropdown'">
							<mat-label position="stacked">{{question.field}}</mat-label>
							<mat-select [(ngModel)]="newRiderResponse[i].response">
						      <mat-option *ngFor="let option of question.option" value="option">{{option}}</mat-option>
						    </mat-select>
						</mat-form-field>					

					</div>


					
				</div>

			<br>
			<div>
				<button mat-raised-button (click)="createRider(riderQuestion)">Create</button>
			</div>

		</div>
	
</div>



<div *ngIf="update">
	<h1 mat-dialog-title>Update Rider</h1>
	<div mat-dialog-content>

		<div *ngFor="let question of newRiderResponse; let i = index;">
					
			<div [ngSwitch]="question.type">

				<mat-form-field *ngSwitchCase="'short'">
					<mat-label position="stacked">{{question.field}}</mat-label>
					<input matInput [(ngModel)]="newRiderResponse[i].response">
				</mat-form-field>

				<mat-form-field *ngSwitchCase="'long'">
					<mat-label position="stacked">{{question.field}}</mat-label>
					<textarea matInput [(ngModel)]="newRiderResponse[i].response"></textarea>
				</mat-form-field>

				<mat-form-field *ngSwitchCase="'time'">
					<mat-label position="stacked">{{question.field}}</mat-label>
					<input matInput type="date" displayFormat="h:mm A" pickerFormat="HH:mm" [(ngModel)]="newRiderResponse[i].response" [min]="newRiderResponse[i].minTime" [max]="newRiderResponse[i].maxTime">
				</mat-form-field>

				<mat-form-field *ngSwitchCase="'date'">
					<mat-label position="stacked">{{question.field}}</mat-label>
					<input matInput type="date" [max]="newRiderResponse[i].maxDateFuture" [min]="newRiderResponse[i].minDate" [(ngModel)]="newRiderResponse[i].response">
				</mat-form-field>

				<mat-form-field *ngSwitchCase="'dropdown'">
					<mat-label position="stacked">{{question.field}}</mat-label>
					<mat-select [(ngModel)]="newRiderResponse[i].response">
				      <mat-option *ngFor="let option of question.option" value="option">{{option}}</mat-option>
				    </mat-select>
				</mat-form-field>					

			</div>
					
		</div>


		<br>
		<div>
			<button mat-raised-button (click)="updateRider(riderDocId)">Update</button>
		</div>

	</div>
</div>

<div *ngIf = "delete">
	<h1 mat-dialog-title>Delete Rider</h1>
	<p>Delete {{riderName}}</p>
	<div>
		<button mat-raised-button (click)="deleteRider(riderDocId)">Delete</button>
	</div>
</div>


<div *ngIf="display">
	<strong><h3>Authenticating User: {{data.riderName}}</h3></strong>
	<strong><h3>Expire In: {{counter}} second</h3></strong>
	<qrcode [qrdata]="QRMsg" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
</div>
