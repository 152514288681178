import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AngularFireAuthGuard,redirectUnauthorizedTo,redirectLoggedInTo} from '@angular/fire/auth-guard';

import { LoginComponentComponent } from './login-component/login-component.component';
import { PanelComponentComponent } from './panel-component/panel-component.component';
import { DashboardComponentComponent } from './dashboard-component/dashboard-component.component';
import { ItemComponentComponent } from './item-component/item-component.component';
import { CategoryComponentComponent } from './category-component/category-component.component';
import { OrderComponentComponent } from './order-component/order-component.component';
import { MenuComponentComponent } from './menu-component/menu-component.component';
import { PostComponentComponent } from './post-component/post-component.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToPanel = () => redirectLoggedInTo(['panel']);

const routes: Routes = [
	{ path: '', component: LoginComponentComponent,
		canActivate: [AngularFireAuthGuard],
		data: { authGuardPipe: redirectLoggedInToPanel },
	},
	{ path: 'login',component: LoginComponentComponent,
		canActivate: [AngularFireAuthGuard],
		data: { authGuardPipe: redirectLoggedInToPanel },
	},
	{ path: 'panel',component: PanelComponentComponent,
		canActivate: [AngularFireAuthGuard],
		data: { authGuardPipe: redirectUnauthorizedToLogin },
		children:[
			{
				path:'dashboard',
				component:DashboardComponentComponent
			},
			{
				path:'item',
				component: ItemComponentComponent
			},
			{
				path:'category',
				component: CategoryComponentComponent
				
			},
			{
				path:'post',
				component: PostComponentComponent
			},
			{
				path:'menu',
				component: MenuComponentComponent
			},
			{
				path:'',
				component:DashboardComponentComponent
			},
		]
	}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
