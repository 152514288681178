<div *ngIf="create">
	<h1 mat-dialog-title>Create Category</h1>
	<mat-form-field class="example-full-width">
		<mat-label>Category Name</mat-label>
		<input matInput type="text" placeholder="" [(ngModel)]="categoryName">
	</mat-form-field>

	<div>
		<mat-label>Order Tray</mat-label>
		<div *ngFor="let tray of ms.orderTrayArr">
			<ul>
				<li>{{tray.trayName}}<mat-checkbox style="float: right;" (change)="onChange(tray.trayId, $event.checked)"></mat-checkbox></li>
			</ul>
		</div>
	</div>

	<mat-label>Category Status</mat-label>
	<div>
		<mat-slide-toggle [(ngModel)]="categoryStatus">Active</mat-slide-toggle>
	</div>

	<br>
	<div>
		<button mat-raised-button (click)="createCategory()">Create</button>
	</div>
</div>


<div *ngIf="update">
	<h1 mat-dialog-title>Update Category</h1>
	<mat-form-field class="example-full-width">
		<mat-label>Category Name</mat-label>
		<input matInput type="text" placeholder="" [(ngModel)]="categoryName">
	</mat-form-field>

	<div>
		<mat-label>Order Tray</mat-label>
		<div *ngFor="let tray of ms.orderTrayArr">
			<ul>
				<li>{{tray.trayName}}<mat-checkbox style="float: right;" (change)="onChange(tray.trayId, $event.checked)" [checked]="checkTray(tray.trayId)"></mat-checkbox></li>
			</ul>
		</div>
	</div>

	<mat-label>Category Status</mat-label>
	<div>
		<mat-slide-toggle [(ngModel)]="categoryStatus">Active</mat-slide-toggle>
	</div>

	<br>
	<div>
		<button mat-raised-button (click)="updateCategory(data.categoryId)">Update</button>
	</div>
</div>


<div *ngIf="delete">
	<h1 mat-dialog-title>Delete Category</h1>
	<p>Delete {{data.categoryName}}</p>
	<div>
		<button mat-raised-button (click)="deleteCategory(data.categoryId)">Delete</button>
	</div>
</div>

