<div *ngIf="detail">
	<h1 mat-dialog-title>{{this.name}}</h1>
		<div mat-dialog-content>
			<mat-label>Description</mat-label>
			<p>{{this.description}}</p>
			<mat-label>Price</mat-label>
			<p>{{this.price}}</p>
			<mat-label>Category</mat-label>
			<p>{{this.categoryNameName}}</p>
			<mat-label>Tag</mat-label>
			<p>{{this.tag}}</p>
			<br><br>
			<div>
				<mat-icon *ngIf="ms.checkEditItem" (click)="openUpdateItemDialog(this.data.itemId)" aria-hidden="false">edit</mat-icon>&nbsp;
				<mat-icon *ngIf="ms.checkEditItem" (click)="openDeleteItemDialog(this.data.itemId)" aria-hidden="false">delete</mat-icon>
			</div>
		</div>
</div>




<div *ngIf="create">
	<h1 mat-dialog-title>Create Item</h1>
		<div mat-dialog-content>

			<div>
				<mat-form-field class="example-full-width">
			    	<mat-label>Product Name</mat-label>
			    	<input matInput [(ngModel)]="name">
				</mat-form-field>
			</div>
				
			<div>
				<mat-form-field class="example-full-width">
			    	<mat-label>Price</mat-label>
			    	<input type="number" matInput [(ngModel)]="price">
				</mat-form-field>
			</div>

			<div>
				<mat-form-field class="example-full-width">
			    	<mat-label>Description</mat-label>
			    	<textarea matInput [(ngModel)]="description"></textarea>
				</mat-form-field>
			</div>

			<div>
				<mat-form-field class="example-full-width">
			    	<mat-label>Tag</mat-label>
			    	<input matInput [(ngModel)]="tag">
				</mat-form-field>
			</div>

			<div>
					<mat-label>Picture Upload</mat-label>
					<input type="file" accept=".png,.jpg" (change)="uploadFile($event)">
			</div>
			<br>
			<div>
				<button mat-raised-button (click)="createItem()">Create Item</button>
			</div>

		</div>
	
</div>



<div *ngIf="update">
	<h1 mat-dialog-title>Update Item</h1>
	<div mat-dialog-content>

		<div>
			<mat-form-field class="example-full-width">
		    	<mat-label>Product Name</mat-label>
		    	<input matInput [(ngModel)]="name">
			</mat-form-field>
		</div>
			
		<div>
			<mat-form-field class="example-full-width">
		    	<mat-label>Price</mat-label>
		    	<input type="number" matInput [(ngModel)]="price">
			</mat-form-field>
		</div>

		<div>
			<mat-form-field class="example-full-width">
		    	<mat-label>Description</mat-label>
		    	<textarea matInput [(ngModel)]="description"></textarea>
			</mat-form-field>
		</div>

		<div>
			<mat-form-field class="example-full-width">
		    	<mat-label>Category</mat-label>
		    	<mat-select [(ngModel)]="categorySelectListName">
			    	<mat-option *ngFor="let category of ms.categoryList | async" [value]="category.categoryId">
			      		{{category.categoryName}}
			    	</mat-option>
			  	</mat-select>
			</mat-form-field>
		</div>

		<div>
			<mat-label>Status</mat-label>&nbsp;
			<mat-slide-toggle [(ngModel)]="itemStatus">Active</mat-slide-toggle>
		</div><br>

		<div>
			<mat-form-field class="example-full-width">
		    	<mat-label>Tag</mat-label>
		    	<input matInput [(ngModel)]="tag">
			</mat-form-field>
		</div>

		<div>
				<mat-label>Picture Upload</mat-label>
				<input type="file" accept=".png,.jpg" (change)="uploadFile($event)">
		</div>
		<br>
		<div>
			<button mat-raised-button (click)="updateItem(data.itemId)">Update Item</button>
		</div>

	</div>
</div>

<div *ngIf = "delete">
	<h1 mat-dialog-title>Delete Item</h1>
	<p>Delete {{this.name}}</p>
	<div>
		<button mat-raised-button (click)="deleteItem(data.itemId, this.imageURL)">Delete Item</button>
	</div>
</div>