import { Component, OnInit } from '@angular/core';
import { UserServiceService } from '../user-service.service';
import { PermissionServiceService } from '../permission-service.service';
import { ViewServiceService } from '../view-service.service'
import { Permission } from '../permission-service.service';
import { OrderServiceService } from '../order-service.service';
import { OrderIncompleteDialogComponent } from '../order-incomplete-dialog/order-incomplete-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable, Observer, throwError} from 'rxjs';

@Component({
  selector: 'app-panel-component',
  templateUrl: './panel-component.component.html',
  styleUrls: ['./panel-component.component.scss']
})
export class PanelComponentComponent implements OnInit {

	memberTab:boolean = false
	deliveryTab:boolean = false
	menuTab:boolean = false
	orderTab:boolean = false
	postTab:boolean = false
	profileTab:boolean = false
  packageTab:boolean = false

  constructor(public us:UserServiceService, public ps:PermissionServiceService, public vs:ViewServiceService, public os:OrderServiceService, public dialog: MatDialog,private afMessaging: AngularFireMessaging, private afs:AngularFirestore) {
  	
  	this.vs.currentOrgObs.subscribe( org => {

  		if(org){

	  		this.memberTab = this.ps.checkPermission(org + '.tab.memberTab');
	  		this.deliveryTab = this.ps.checkPermission(org + '.tab.deliveryTab');
	  		this.menuTab = this.ps.checkPermission(org + '.tab.menuTab');
	  		this.orderTab = this.ps.checkPermission(org + '.tab.orderTab');
	  		this.postTab = this.ps.checkPermission(org + '.tab.postTab');
	  		this.profileTab = this.ps.checkPermission(org + '.tab.profileTab');

        this.packageTab = this.ps.checkPermission(org + '.tab.packageTab');
        
        this.requestPermission(org);
        this.listen();

  		}

  	})

  	this.os.gotNewIncomplete.subscribe( data => {
      // console.log(data);
  		if(data){
        const dialogRef = this.dialog.open(OrderIncompleteDialogComponent, {
          width : '250px',
          disableClose: true,
        })


  		}

  	})

    


  }

  ngOnInit(): void {
  }


  requestPermission(orgId) {
    this.afMessaging.requestToken
      .subscribe(
        (token) => {
          console.log('Permission granted! Save to the server!', token);
          let docSub = this.afs.doc('organization/' + orgId).valueChanges().subscribe( (docDat:any) => {
            let tokenArr:any = docDat.organizationNotificationToken;

            docSub.unsubscribe();

            if(tokenArr.includes(token)){

            }else{
              tokenArr.push(token);
              this.afs.doc('organization/' + orgId).update({
                organizationNotificationToken:tokenArr
              })
            }
          })
        },
        (error) => { console.error(error); },  
      );
  }

  listen() {
    this.afMessaging.onMessage((payload) => {
      // console.log(payload)
      const dialogRef = this.dialog.open(OrderIncompleteDialogComponent, {
          width : '250px',
          disableClose: true,
        })
    })
  }


}
