import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-member-dialog',
  templateUrl: './member-dialog.component.html',
  styleUrls: ['./member-dialog.component.scss']
})
export class MemberDialogComponent implements OnInit {

	editUser:any

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, private afs:AngularFirestore) {

  	this.editUser = []
  	console.log(this.data.user)
  	for(let response of this.data.question){
  		let qField = response.field
  		if(response.type != 'auth-phone'){
	  		this.editUser.push({
	  			type:response.type,
	  			field:qField,
	  			response:this.data.user['member' + qField.split(' ').join('')]
	  		})
  		}
  	}

  	this.editUser.push({
		type:'auth-phone',
		field:'Authenticated Phone',
		response:this.data.user['memberPhone']
	})

  	console.log(this.editUser)


  }

  ngOnInit(): void {
  }

  updateUser(){

  	for(let question of this.editUser){

	  	if(question.type != 'auth-phone'){

	  		this.afs.doc('organization/' + this.data.org +'/members/' + this.data.user.memberDocId).update({
	  			['member' + question.field]:question.response
		  	})

	  	}
  	}





  }

}

export interface DialogData{
	operation:string
	user:any
	question:any[]
	org:string
}
