import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore'; 
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import { PermissionServiceService } from './permission-service.service';

@Injectable({
  providedIn: 'root'
})

export class MenuServiceService {

  categoryDisplay : Category[] = []
	categoryList : Observable<Category[]>;
  categoryArr : any[];
  categorysCollection : AngularFirestoreCollection<Category>;
  categorySub : any;
  currentOutlet : any;
  currentCategory : string = "";
  itemList: Observable<Item[]>;
  itemsCollection : AngularFirestoreCollection<Item>;
  itemFilterArr : any[] = [];
  orderTrayArr : any[] = [];
  currentOutletArr : any[] = [];
  currentCategoryName : string;

  checkCreateCategory : boolean = false;
  checkViewCategory : boolean = false;
  checkEditCategory : boolean = false;

  checkCreateItem : boolean = false;
  checkEditItem : boolean = false;
  checkViewItem : boolean = false;

  checkViewOutlet : boolean = false;
  checkOutletToggle : boolean = false;

  allowedPermission:string[];
  currentUser:any;
  userUID:string;

  constructor(private afs:AngularFirestore, public auth: AngularFireAuth, public ps:PermissionServiceService) { }

  fetchCategory(outlet) {
      this.currentOutlet = outlet.outletId;
      this.categorysCollection = this.afs.collection<Category>('outlet/'+this.currentOutlet+'/category', ref => ref.orderBy('categoryIndex'));
      this.categoryList = this.categorysCollection.valueChanges({idField:'categoryId'})
      this.categoryList.subscribe( data => {
        this.categoryArr = data;
      })

   }

   fetchItem(outlet) {
      this.itemsCollection = this.afs.collection<Item>('outlet/' + this.currentOutlet + '/item');
      this.itemList = this.itemsCollection.valueChanges({idField:'itemId'});
   }

   filterItem(cat : string) {
     
     this.checkOutletToggle = this.ps.checkPermission(this.ps.allowedOrganization[0]+'.'+this.currentOutlet+'.toggleStatus')
     this.checkViewOutlet = this.ps.checkPermission(this.ps.allowedOrganization[0]+'.'+this.currentOutlet+'.viewOutlet')

     this.checkViewCategory = this.ps.checkPermission(this.currentOutlet+'.category.viewCategory');
     this.checkCreateCategory = this.ps.checkPermission(this.currentOutlet+'.category.createCategory');
     this.checkEditCategory = this.ps.checkPermission(this.currentOutlet+'.category.editCategory');

     this.checkCreateItem = this.ps.checkPermission(this.currentOutlet+'.item.createItem');
     this.checkViewItem = this.ps.checkPermission(this.currentOutlet+'.item.viewItem');
     this.checkEditItem = this.ps.checkPermission(this.currentOutlet+'.item.editItem');

     if(cat == null) {
       this.itemFilterArr = [];
       this.afs.collection<Category>('outlet/'+this.currentOutlet+'/category', ref => ref.orderBy('categoryIndex')).valueChanges({idField:'categoryId'}).subscribe( data => {
         if(data.length == 0) {
           this.currentCategoryName = "";
         }
         else {
           this.currentCategoryName = data[0].categoryName;
           this.filterItem(data[0].categoryId);
         }
       })
     }
     else {
       this.currentCategory = cat;
      
       let subs = this.afs.collection<Item>('outlet/' + this.currentOutlet + '/item').valueChanges({idField:'itemId'}).subscribe( data => {
         this.itemFilterArr.length = 0;
         for(var i = 0; i < data.length; i++) {
           if(data[i].itemCategory == cat) {
             this.itemFilterArr.push(data[i]);
           }
         }
       })

     }
     
     
   }

   fetchOrderTray(event) {
     let number = false;
     event.subscribe( data => {
       this.orderTrayArr = data;
     })
   }


}

export interface Category {
  categoryName : string;
  categoryStatus : boolean;
  categoryIndex : number;
  categoryTray : string[];
}

export interface Item {
  itemName : string;
  itemPrice : number;
  itemDesc : string;
  itemCategory : string;
  itemTag : string;
  itemURL : string;
}

export interface Outlet {
  outletName : string;
  outletId : string;
}

export interface Permission{
  groupId:string
  groupMember:any[]
  groupOrganization:string
  groupPermission:string[]
}