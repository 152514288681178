import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'categoryFilter'
})
export class CategoryFilterPipe implements PipeTransform {

  transform(items: any[], catOption: any[]){


  	// return items.filter( it => {

  	// 	for(let itemCategory of it.orderItem){}

  	// 	for(let category of catOption){
  	// 		console.log(it,category)
  	// 		if(it.itemCategory == category.category && category.show){
  	// 			return true
  	// 		}
  	// 	}


  	// });

  }

}
