import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-checkout-component',
  templateUrl: './checkout-component.component.html',
  styleUrls: ['./checkout-component.component.scss']
})
export class CheckoutComponentComponent implements OnInit {

	selectedProfile:OrderProfile

	activeProfileCollection:AngularFirestoreCollection<OrderProfile>
	activeProfile:Observable<OrderProfile[]>

	orderResponse = []
	itemArr = []

  constructor(private afs:AngularFirestore) {

  	this.activeProfileCollection = this.afs.collection<OrderProfile>('order-profile' , ref => ref.where('profileOrganization','==','demo2'));		/////Dummy Value!!
  	this.activeProfile = this.activeProfileCollection.valueChanges({idField:'profileId'})

  	this.itemArr = [
  		{
  			itemName:'itemName3',
  			itemPrice:3,
  			itemCategory:'cat3',
        itemTray:['J93S3myqNRb31AiaD2ZQ','SdHAjNTksRfdGzBiKAm9']
  		},
  		{
  			itemName:'itemName4',
  			itemPrice:4,
  			itemCategory:'cat4',
        itemTray:['6TDF3Q2LSSr89fm1B01D','SdHAjNTksRfdGzBiKAm9']
  		}
  	]

  }

  ngOnInit(): void {
  }

  chooseProfile(op){
  	this.selectedProfile = op

  	this.orderResponse = []

  	for(let question of this.selectedProfile.profileQuestion){

  		let newQuestion = {
  			field:question.field,
  			response:''
  		}

  		this.orderResponse.push(newQuestion)
  	}

  }

  checkout(profile){
  	console.log(this.orderResponse)

    let trayArr = []

    for(let item of this.itemArr){
      for(let tray of item.itemTray){
        if(!trayArr.includes(tray)){
          trayArr.push(tray)
        }
      }
    }

    let orderObj ={
      orderResponse:this.orderResponse,
      orderStatus:false,
      orderProfile:profile.profileId,
      orderTimestamp:new Date(),
      orderTray:trayArr
    }

    for(let tray of trayArr){

      orderObj[tray] = {
        trayItem:[],
        trayStatus:false
      }

      for(let item of this.itemArr){

        if(item.itemTray.includes(tray)){
          orderObj[tray].trayItem.push(item)
        }


      }
    }



  	this.afs.collection('order').add(orderObj)

  }

}

export interface OrderProfile{
	profileId:string
	profileName:string
	profileQuestion:any[]
	profileStatus:boolean
	profileAuth:boolean
}