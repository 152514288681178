<h1>Edit User</h1>

<div *ngIf="data.operation == 'editUser'">

	<div *ngFor="let question of editUser">

		<div [ngSwitch]="question.type">
			
			<div *ngSwitchCase="'auth-phone'">
				<p>Authenticated Phone: {{question.response}}</p>
			</div>

			<div *ngSwitchDefault>
				<mat-form-field class="full-width" >
					<mat-label>{{question.field}}</mat-label>
					<input matInput placeholder="" [(ngModel)]="question.response">	
				</mat-form-field>
			</div>



		</div>
		
	</div>

	<button mat-raised-button (click)="updateUser()">Update</button>

</div>