import { Pipe, PipeTransform } from '@angular/core';
import { OrderServiceService } from './order-service.service'

@Pipe({
  name: 'orderFilter'
})
export class OrderFilterPipe implements PipeTransform {

	constructor(private os:OrderServiceService) {

    }

  transform(items: any[], searchText: string): any[] {

    if(!items) return [];
    if(!searchText) return items;
    
    searchText = searchText.toLowerCase();
    return items.filter( it => {



      let responseValue = [];

      Object.keys(it).forEach(function(key) {
          var value = it[key];

          if(typeof(value) === 'string'){
          responseValue.push(value.toLowerCase());
          }else{
            value = value.toString(10)
            responseValue.push(value);
          }
      });

      for(let response of responseValue){  ////filter reponse
        if(response.includes(searchText)){
          return true
        }
      }

    	let itemValues = [];

    	for(let item of it.trayItem){  ////filter item

    		Object.keys(item).forEach(function(key) {
			    var value = item[key];

			    if(typeof(value) === 'string'){
					itemValues.push(value.toLowerCase());
			    }else{
			    	value = value.toString(10)
			    	itemValues.push(value);
			    }

			});

	    	for(let value of itemValues){
	    		if(value.includes(searchText)){
	    			return true
	    		}
	    	}
    		
    	}

    	////let's convert the profileId into string

    	let profileName = [];

    	for(let profile of this.os.orderProfileArr){ // filter profile
			if(it.trayProfile == profile.profileName){
				profileName.push(profile.profileName)
			}
		}

		for(let pn of profileName){
	    	if(pn.toLowerCase().includes(searchText)){
    			return true
    		}			
		}
      
    });




   } 

}

