import { Component, OnInit } from '@angular/core';
import { PermissionServiceService } from '../permission-service.service';
import { ViewServiceService } from '../view-service.service';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore'; 
import { OrderServiceService } from '../order-service.service';
import { MenuServiceService } from '../menu-service.service';

@Component({
  selector: 'app-menu-component',
  templateUrl: './menu-component.component.html',
  styleUrls: ['./menu-component.component.scss']
})
export class MenuComponentComponent implements OnInit {

	outletStatus : boolean = false;

  constructor(public ps:PermissionServiceService, public vs:ViewServiceService, private afs : AngularFirestore, public os:OrderServiceService, public ms:MenuServiceService) { 
  }

  ngOnInit(): void {
  }

  toggleOutletStatus(id : string, status : boolean) {
  	// console.log('outlet id', id);
  	this.afs.doc('outlet/' + id).update({
  		outletStatus : status,
  	});

  }

}

