
<div style="display: flex;">

	<div>
		<div style="display: flex;justify-content: space-between;align-items: center;">
			<p style="padding: 0;margin: 0;">Categories</p>
			<button *ngIf="ms.checkCreateCategory" mat-button (click)="createCategory()">New</button>
		</div>
		<div *ngIf="ms.checkViewCategory" style="min-width: 250px;max-width: 250px;" cdkDropList [cdkDropListData]="ms.categoryArr" class="example-list" (cdkDropListDropped)="drop($event)">
	<!-- 		<div >
	 -->
				<div class="example-box" *ngFor="let category of ms.categoryArr" cdkDrag (click)="filterCategory(category.categoryId, category.categoryName)">{{category.categoryName}}

				  	<span>
				  		<mat-icon *ngIf="ms.checkEditCategory" (click)="updateCategory(category.categoryId, category.categoryName, category.categoryStatus, category.categoryTray)" aria-hidden="false">edit</mat-icon>&nbsp;
				  		<mat-icon *ngIf="ms.checkEditCategory" (click)="deleteCategory(category.categoryId, category.categoryName)" aria-hidden="false">delete</mat-icon>&nbsp;
				  		<!-- <mat-icon (click)="filterCategory(category.categoryName)" aria-hidden="false">keyboard_arrow_right</mat-icon> -->
				  	</span>
				  		  	
				  </div>

	<!-- 		</div>
	 -->	  
		</div>
	</div>


	<div style="width: 100%;">
		<app-item-component></app-item-component>
	</div>
	
</div>


<!-- <br><br>
<hr>
<div>
	<ul>
		<li *ngFor="let category of ms.categoryArr" >
			{{category.categoryName}}
		
		</li>

	</ul>
	
</div> -->