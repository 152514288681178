import { Component, OnInit, Inject } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore'; 
import { AngularFireStorage } from '@angular/fire/storage';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { PermissionServiceService } from '../permission-service.service';
import { OrderServiceService } from '../order-service.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';

@Component({
  selector: 'app-delivery-dialog',
  templateUrl: './delivery-dialog.component.html',
  styleUrls: ['./delivery-dialog.component.scss']
})
export class DeliveryDialogComponent implements OnInit {

	create : boolean = false;
	update : boolean = false;
	delete : boolean = false;
  display : boolean = false;

	name : string;
	carPlate : string;
	model : string;
	phone : string;

  riderName : string;
  riderDocId : string;
  orgId : string;
  QRMsg : string;
  counter : number;

  riderQuestion : any[] = [];

  newRiderResponse:any[] = [];

  constructor(private http: HttpClient, private afs:AngularFirestore, private ps:PermissionServiceService, @Inject(MAT_DIALOG_DATA) public data : any, public dialog:MatDialog, public os:OrderServiceService, public auth: AngularFireAuth) {

    this.os.currentOrgObs.subscribe( org => {
      if(org) {
        this.afs.doc('organization/'+org).valueChanges().subscribe( (question:any) => {
          this.riderQuestion = question.organizationRiderQuestion;
          this.selectOrderType(this.riderQuestion);
        })
      }
    })
  }

  ngOnInit(): void {
  	if(this.data == null) {
  		this.create = true;
  	} else {

  		if(this.data.method == "update") {
        
        this.os.currentOrgObs.subscribe( org => {
          if(org) {
            this.orgId = org;

            this.afs.doc('organization/'+org).valueChanges().subscribe( (question:any) => {
                this.newRiderResponse = [];
                for(var i=0; i<this.data.rider.length; i++) {
                  for(let ques of question.organizationRiderQuestion) {
                    if(ques.field.toLowerCase().split(' ').join('') == this.data.rider[i].field.toLowerCase().split(' ').join('')){
                      let newQuestion = ques;
                      newQuestion['response'] = this.data.rider[i].response;
                      this.newRiderResponse.push(newQuestion);
                      break;
                    }
                  }
                }
                console.log('quest', this.riderQuestion);
                console.log(this.newRiderResponse);
            })


          }
        })

        

        for(var i=0; i<this.data.rider.length; i++) {
          if(this.data.rider[i].field == "Name") {
            this.riderName = this.data.rider[i].response;
          }
          if(this.data.rider[i].field == "riderId") {
            this.riderDocId = this.data.rider[i].riderId;
          }
        }

  			this.update = true;
  		}
  		else if(this.data.method == "delete") {
        for(var i=0; i<this.data.rider.length; i++) {
          if(this.data.rider[i].field == "Name") {
            this.riderName = this.data.rider[i].response;
          }
          if(this.data.rider[i].field == "riderId") {
            this.riderDocId = this.data.rider[i].riderId;
          }
        }
  			this.delete = true;
  		}
      else if(this.data.method == "QR") {
        this.display = true;
        //riderDocId:riderDocId + 
        //operation:authenticate
        //organization:orgId

        let timestamp = new Date(Date.now());

        let body = {
          riderDocId: this.data.riderDocId,
          operation: 'authenticate',
          organization: this.data.organization,
          timeStamp: timestamp.getSeconds(),
        }

        this.QRMsg = JSON.stringify(body);
        this.counter = 60;
        let t = setInterval(() => {
          if(this.counter<=0) {
            this.dialog.closeAll();
            clearInterval(t);
          }
          this.counter = this.counter-1;
        }, 1000);
      }
  	}
  }

  selectOrderType(profile : any){

    this.newRiderResponse = []    

    for(let question of profile){

      if(question.type == 'time'){

        this.newRiderResponse.push({
          field:question.field,
          response:'',
          minTime: question.minTime,
          maxTime: question.maxTime
        })    

      }

      else if(question.type == 'date'){

        let dayAhead = question.maxDateFuture;
        let maxString;

        if(dayAhead){
          let maxDateObject = new Date(Date.now())
          maxDateObject.setDate(maxDateObject.getDate() + Number(dayAhead))
          maxString = maxDateObject.toISOString()
        }else{
          maxString = new Date(new Date().getFullYear(), 11, 31).toISOString()
        }


        this.newRiderResponse.push({
          field:question.field,
          response:'',
          minDate: new Date(Date.now()).toISOString(),
          maxDateFuture: maxString
        })  

      }else{
        this.newRiderResponse.push({
          field:question.field,
          response:''
        })        
      }

    }

    
  }

  createRider(profile : any) {
    let riderUID = "";

    let riderResponse = {};

    for(let question of profile){
      if(question.type == "time"){
        for(let res of this.newRiderResponse){  

          if(question.field == res.field){

            if(question.timeOnly){
             let obj = new Date(res.response)

             res.response = obj.getHours() + ':' + obj.getMinutes();
             delete res.maxTime;
             delete res.minTime;
             res.timeOnly = question.timeOnly;
             res.type = "time";
            }
          }
        }
      }
      if(question.type == "date"){
        for(let res of this.newRiderResponse){  

          if(question.field == res.field){
            res.type = "date";
            delete res.maxDateFuture;
          }
        }
      }
    }

    for(let res of this.newRiderResponse){
      riderResponse['rider' + res.field] = res.response;
    }

    riderResponse['riderStatus'] = "off-duty";
    riderResponse['riderOrder'] = null;    

  	this.afs.collection('organization/' + this.ps.allowedOrganization + '/rider').add(riderResponse);
    
  	this.dialog.closeAll();
  }

  updateRider(id : string) {

    let riderResponse = {};
    for(let res of this.newRiderResponse){
      riderResponse['rider' + res.field] = res.response;
    }
  	this.afs.doc('organization/' + this.ps.allowedOrganization + '/rider/' + id).update(riderResponse);
  	this.dialog.closeAll();

  }

  deleteRider(id : string) {
  	this.afs.doc('organization/' + this.ps.allowedOrganization + '/rider/' + id).delete();
	this.dialog.closeAll();
  }

}