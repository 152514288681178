import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore'; 
import { Observable,Observer } from 'rxjs';
import { Route, Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MenuServiceService } from './menu-service.service';
import { OrderServiceService } from './order-service.service'
import { PermissionServiceService } from './permission-service.service';

@Injectable({
  providedIn: 'root'
})
export class ViewServiceService {

	viewOutlet:any
  viewOrg:any

  createProfile:any
  currentOrgObs:Observable<string>

  constructor(private router: Router,private route: ActivatedRoute,private ms:MenuServiceService,private afs:AngularFirestore, private os:OrderServiceService, private ps:PermissionServiceService) {
    
  	this.viewOutlet = {
  		outletId:'',
  		outletName:''
  	}

    this.currentOrgObs = Observable.create( (observer:Observer<string>) => {      
      let interval = setInterval(() => {
        observer.next(this.viewOrg)
        if(this.viewOrg){
          clearInterval(interval)
          observer.complete()
        }        
      }, 100);
    })

    let initOutlet = this.ps.allowedOutletSub.subscribe( data => {

      if(data){
        this.switchOutlet(data[0])
        initOutlet.unsubscribe()
      }

    })

    this.route.queryParams.subscribe(params => {

        let outletId = params['outlet'];

        if(outletId){
        	this.afs.doc('outlet/' + outletId).valueChanges().subscribe( data => {
        		this.viewOutlet = data
        		this.viewOutlet.outletId = outletId
            this.viewOrg = this.viewOutlet.outletOrganization
            this.os.fetchOrderProfile(this.viewOrg)
        	})
        }
    })




  }

  switchOrg(org){
  	this.router.navigate(['panel'],{relativeTo: this.route, queryParams: {org:org}})
    this.viewOrg = org
    this.os.fetchOrderProfile(this.viewOrg)
  }



  switchOutlet(outlet){
  	this.viewOutlet = outlet
  	this.ms.fetchCategory(outlet)
    this.ms.fetchItem(outlet)
    this.ms.filterItem(null);
  	this.router.navigate(['panel'], {queryParams: {outlet:outlet.outletId}});
    this.viewOrg = outlet.outletOrganization
    this.os.fetchOrderProfile(this.viewOrg)
  }

  outletView(aspect){
  	this.router.navigate(['panel/' + aspect], { queryParams: {outlet:this.viewOutlet.outletId,page:aspect}});
  }



}
