<div *ngIf="data.operation == 'showAvailableVariable'" style="display: flex;">
	<mat-select [(ngModel)]="selectedVariable">
		<mat-option value="default">Select One</mat-option>
		<mat-option *ngFor="let question of variableOpt" [value]="question">{{question.field}}</mat-option>
	</mat-select>

	<button mat-raised-button color="primary" (click)="onNoClick()">Insert</button>
</div>

<div *ngIf="data.operation == 'newOrderProfile'">
	
	<h1>New Profile</h1>
	<mat-form-field class="full-width">
		<mat-label>Profile Name</mat-label>
		<input matInput placeholder="" value="" [(ngModel)]="newProfileName">
	</mat-form-field>


	<button mat-raised-button (click)="os.addNewProfile(newProfileName)">Add</button>

</div>

<div *ngIf="data.operation == 'updateProfileName'">
	
	<h1>Edit Profile</h1>
	<mat-form-field class="full-width">
		<mat-label>Profile Name</mat-label>
		<input matInput placeholder="" value="" [(ngModel)]="editProfileName">
	</mat-form-field>


	<button mat-raised-button (click)="os.updateProfileName(editProfileName)">Update</button>

</div>

<div *ngIf="data.operation == 'editOrder'">
	
	<h1>Edit Order</h1>

	<div style="width: 100%;display: flex;">
		<div style="width: 50%;">
				<h3>Response</h3>
				<div *ngFor="let info of editOrder | keyvalue;">

					<div [ngSwitch]="info.key">
						


						<div *ngSwitchCase="'orderId'">
					
						</div>

						<div *ngSwitchCase="'orderItem'">
						
						</div>

						<div *ngSwitchCase="'orderProfile'">
							<mat-form-field class="full-width">
								<mat-label>Profile</mat-label>
								<mat-select [(ngModel)]="editOrder.orderProfile" (selectionChange)="changeOrderProfile()">				 
							    	<mat-option *ngFor="let profile of os.orderProfileArr" value="{{profile.profileName}}">{{profile.profileName}}</mat-option>
								</mat-select>
							</mat-form-field>
						</div>

						<div *ngSwitchCase="'orderResponse'">
							<div *ngFor="let res of info.value">

								<div [ngSwitch]="res.type">

									<mat-form-field *ngSwitchCase="'date'" class="full-width">
										<mat-label>{{res.field}}</mat-label>
										<input matInput [matDatepicker]="picker" [(ngModel)]="res.response">
										<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
										<mat-datepicker #picker></mat-datepicker>
									</mat-form-field>

									<div *ngSwitchCase="'auth-user'">

									</div>

									<div *ngSwitchCase="'location'">

										<agm-map [zoom]="16" [latitude]="marker.position.latitude" [longitude]="marker.position.longitude" >
						  
										  <agm-marker [latitude]="marker.position.latitude" [longitude]="marker.position.longitude"></agm-marker>

										</agm-map>

										<mat-label>Address</mat-label>
							  		  	<div style="display: flex;">
											<input matInput  [(ngModel)]="searchAddress">
											<button mat-button (click)=requestGeoAPI()>GO</button>
										</div>



									</div>

									<mat-form-field *ngSwitchCase="'rider'">
										<mat-label>{{res.field}}</mat-label>
										<mat-select [(ngModel)]="res.response">
											<mat-option *ngFor="let rider of riderArr" [value]="rider.riderDocId">
										      {{rider.riderName}}
										    </mat-option>
										</mat-select>
										<!-- <input matInput placeholder="" value="" [(ngModel)]="res.response"> -->
										
									</mat-form-field>

									<div *ngSwitchCase="'whatsapp-send'">

									</div>

									<div *ngSwitchCase="'time'">

										<div class="ngx-timepicker-field-example">
								            <!-- <ngx-timepicker-field [defaultTime]="'11:11 am'"></ngx-timepicker-field> -->
								            <ngx-timepicker-field [(ngModel)]="timepickerTime" [defaultTime]="timepickerTime" [format]="24"></ngx-timepicker-field>
								        </div>

									</div>

									
									<mat-form-field *ngSwitchDefault class="full-width">
										<mat-label>{{res.field}}</mat-label>
										<input matInput placeholder="" value="" [(ngModel)]="res.response">
									</mat-form-field>


								</div>


								
							</div>	
						</div>

						<div *ngSwitchCase="'orderRemovedItem'">
					
						</div>
						
						<div *ngSwitchDefault>
							<mat-label>{{info.key}}</mat-label>
							<input matInput placeholder="" value="" [(ngModel)]="info.value">
						</div>

					</div>



				</div>
				<mat-form-field class="full-width">
					<mat-label>Status</mat-label>
					<mat-select [(ngModel)]="editOrderTrayStatus"> 
				    	<mat-option [value]="true">Completed</mat-option>
				    	<mat-option [value]="false">Pending</mat-option>
				    	<mat-option [value]="'cancelled'">Cancelled</mat-option>
					</mat-select>
				</mat-form-field>

		</div>
		<div style="width: 50%;">
			<h3>Items</h3>

			<div *ngFor="let item of editOrder.orderItem; let i = index;" style="display: flex;align-items: center;">
				<p style="margin: 0;">{{item.itemName}}</p>
				<button mat-button (click)=editOrderRemoveItem(i)>
					<mat-icon aria-hidden="false">clear</mat-icon>
				</button>
			</div>	
		</div>
	</div>


	<button mat-raised-button (click)="updateOrder()">Update</button>




</div>