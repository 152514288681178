import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { PermissionServiceService } from '../permission-service.service';
import { ViewServiceService } from '../view-service.service';
import { PostDialogComponent } from '../post-dialog/post-dialog.component'
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';



@Component({
  selector: 'app-post-component',
  templateUrl: './post-component.component.html',
  styleUrls: ['./post-component.component.scss']
})
export class PostComponentComponent implements OnInit {

	postBoardCollection:AngularFirestoreCollection<Board>
	postBoards:Observable<Board[]>

  postCollection:AngularFirestoreCollection<Post>
  posts:Observable<Post[]>

  currentBoard:Board

  checkCreatePost:boolean
  togglePostStatus:boolean
  checkEditPost:boolean

  constructor(private afs:AngularFirestore, public vs:ViewServiceService, public dialog:MatDialog,private ps:PermissionServiceService) {

    this.currentBoard = {
      boardName:'',
      boardId:'',
      boardOrganization:''
    }

  	this.vs.currentOrgObs.subscribe( data => {

	  	if(data){
	  		this.postBoardCollection = this.afs.collection<Board>('post-board', ref => ref.where("boardOrganization","==",data));
	    	this.postBoards = this.postBoardCollection.valueChanges({idField:'boardId'})
	    	let loadFirst = this.postBoards.subscribe( data => {
	    		this.viewBoard(data[0])
	    		loadFirst.unsubscribe()
	    	})

        this.checkCreatePost = this.ps.checkPermission(data + '.post.createPost');
        this.togglePostStatus = this.ps.checkPermission(data + '.post.togglePostStatus');
        this.checkEditPost = this.ps.checkPermission(data + '.post.editPost');

	  	}
  	})

  }

  ngOnInit(): void {
  }

  viewBoard(board){

  	this.currentBoard = board
  	

		this.postCollection = this.afs.collection<Post>('post', ref => ref.where("postBoard","==",board.boardId));
    this.posts = this.postCollection.valueChanges({idField:'postId'})  	

  }

  newPost(){
		const dialogRef = this.dialog.open(PostDialogComponent, {
			width: '600px',
			data: {operation:'newPost',boardId:this.currentBoard.boardId}
		});
  }

	editPost(editPost): void {

    if(this.checkEditPost){		
  		const dialogRef = this.dialog.open(PostDialogComponent, {
  		  width: '900px',
  		  data: {operation:'editPost',post:editPost}
  		});
    }

	}

	 changePostStatus(postId:string,postStatus:boolean){

    this.afs.doc('post/' + postId).update({
      postStatus:postStatus
    })
  }





}

export interface Board {
  boardId:string
  boardName:string
  boardOrganization:string

}

export interface Post {
  postId:string
  postName:string
  promoCode:string
  postStatus:string
  postImageURL:string
  postText:string
}