<div style="display: flex;height: 100%;">
	
	<div style="min-width: 200px;background: white;height: 100%">
		
		<mat-list role="list" style="width: 100%">

		</mat-list>



	</div>
	<div style="width: 100%;">

		<div style="width: 50%; float: left; padding: 10px;">
			<mat-card>
				<mat-card-subtitle>
					Current Subscription
				</mat-card-subtitle>

				<mat-card-content>
					<h1>Free</h1>
				</mat-card-content>


			</mat-card>
		</div>


		<div style="width: 50%; float: left; padding: 10px;">

			<mat-card>
				<mat-card-subtitle>
					Received Order
				</mat-card-subtitle>

				<mat-card-content>
    				<h1 style="margin: auto;">{{os.totalOrderNumber}}</h1>

    				<div style="padding: 5px;">
    					<mat-progress-bar mode="determinate" [value]="os.progressBarValue" [color]="os.progressBarColor"></mat-progress-bar>
    					{{os.progressBarValue}}%	
    				</div>
    				
					<p style="font-size: 1rem;">Maximum Order : {{os.orderLimit}}</p>

				</mat-card-content>


			</mat-card>
		</div>

		

	</div>




</div>