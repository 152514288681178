import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable, Observer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PermissionServiceService {

	permissonCollection:AngularFirestoreCollection<Permission>
	permissons:Observable<Permission[]>

	allowedPermission:string[]
	allowedOrganization:string[]

	allowedOutlet:Outlet[]
	allowedOutletSub:Observable<Outlet[]>

	outletCreateCategory:boolean
	organizationRiderQuestion:any

  constructor(private afs:AngularFirestore) {
  	this.outletCreateCategory = false;

  	this.allowedOutletSub = Observable.create( (observer:Observer<Outlet[]>) => {      
      let interval = setInterval(() => {
        observer.next(this.allowedOutlet)
        if(this.allowedOutlet){
          clearInterval(interval)
          observer.complete()
        }        
      }, 100);
    })

  }

  fetchPermission(userUID){
	this.permissonCollection = this.afs.collection<Permission>('permission', ref => ref.where('groupMember','array-contains',userUID))
	this.permissons = this.permissonCollection.valueChanges({idField:'groupId'})

	this.permissons.subscribe( data =>{
			
		this.allowedPermission = [];
		this.allowedOrganization = []

		for(let permGroup of data){		////push all perm as they might be possibility that the user is involved in 2 org
			for(let perm of permGroup.groupPermission){
				if(!this.allowedPermission.includes(perm)){
					this.allowedPermission.push(perm)
				}
			}
			this.allowedOrganization.push(permGroup.groupOrganization)
		}
		this.fetchOutlet()
	})
  }

  checkPermission(action):boolean{  	

  	if(this.allowedPermission.includes(action)){
  		return true;
  	}else{
  		return false;
  	}
  }

  fetchOutlet(){

  	for(let org of this.allowedOrganization){

	  	let outletCollection = this.afs.collection<Outlet>('outlet', ref => ref.where('outletOrganization','==',org))
	  	let outlets = outletCollection.valueChanges({idField:'outletId'})

	  	outlets.subscribe( data => {
	  		this.allowedOutlet = []
	  		for(let outlet of data){

	  			if(this.checkPermission(org+'.'+outlet.outletId+'.viewOutlet')) {
	  				this.allowedOutlet.push(outlet)
	  			}
	  			
	  		}

	  	})

  	}



  }

}

export interface Permission{
	groupId:string
	groupMember:any[]
	groupOrganization:string
	groupPermission:string[]
}

export interface Outlet{
	outletId:string
	outletName:string
	outletPermission:string[]
}