import { Component, OnInit,ViewChild } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { AgmMap } from '@agm/core';

@Component({
  selector: 'app-delivery-map',
  templateUrl: './delivery-map.component.html',
  styleUrls: ['./delivery-map.component.scss']
})
export class DeliveryMapComponent implements OnInit {

	@ViewChild('map') public map: AgmMap;

	lat:any = 3.8056452
	lng:any = 103.3291175
	zoom:number = 13

	allRider:any
	riderMarkers:any = {}

	allMarkers:any

  checkViewMap : boolean = false;

  constructor(private afs:AngularFirestore) {

  	this.afs.collection('organization/mvh/rider', ref => ref.orderBy('riderStatus')).valueChanges({idField:'riderDocId'}).subscribe( (data:any) => {

  		this.allRider = data;

  		for(let rider of data){

  			if(rider.riderStatus == 'idle' || rider.riderStatus == 'in-transit'){

  				this.afs.collection('organization/mvh/rider/' + rider.riderDocId + '/position', ref=> ref.orderBy('timestamp','desc').limit(1)).valueChanges().subscribe( (positionData:any) => {

  					this.riderMarkers[rider.riderDocId] = {
  						position:{
  							lat:positionData[0].position.latitude,
  							lng:positionData[0].position.longitude
  						},
  						labelOption:{
  							color:'black',
  							backgroundColor:'white',
  							text:rider.riderName
  						},
  						riderName:rider.riderName
  					}

  					this.allMarkers = []

  					Object.keys(this.riderMarkers).forEach(marker =>{
  						this.allMarkers.push(this.riderMarkers[marker])
  					})

  				})
  			}

  			let getItem = this.afs.doc('order/' + rider.riderOrder).valueChanges().subscribe( (orderDoc:any) => {

  				getItem.unsubscribe();

  				

  				rider.orderResponse = orderDoc.orderResponse;
  				console.log(rider.orderResponse)
  				rider.orderItem = orderDoc['0Tu9sinevNM6Pc7KEXzJ'].trayItem;

  			})


  		}





  	})


  }


  centerMarker(id){

  	console.log('hi')

  	let targetMarker = this.riderMarkers[id]
  	console.log(targetMarker.position)

  	if(targetMarker){

  		console.log(targetMarker)

  		this.lat = targetMarker.position.lat
  		this.lng = targetMarker.position.lng

  		this.zoom = 15;


  	}


  }

  ngOnInit(): void {
  }

}
