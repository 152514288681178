import { Pipe, PipeTransform } from '@angular/core';
import { PermissionServiceService } from './permission-service.service';
import { ViewServiceService } from './view-service.service';

@Pipe({
  name: 'permissionPipe'
})
export class PermissionPipePipe implements PipeTransform {

	constructor(public ps:PermissionServiceService, public vs:ViewServiceService){

	}

  transform(value: any[], aspect: string): unknown {
   
	if(value){	///thanks to the async Pipe
		  	if(aspect == 'viewTray'){

	  		let allowedTray = [];

	  		for(let tray of value){

	  			if(this.ps.checkPermission('ordertray.' + tray.trayId + '.viewTray')){
	  				allowedTray.push(tray)
	  			}

	  		}


	  		return allowedTray;
	  	}
	}



  }

}
